<template>
  <div class="menus">
    <div class="menu" @click="movePage('employee.manage.index')">
      <div class="content">
        <div class="icon">
          <i class="fas fa-users"></i>
        </div>
        <div class="title">
          사원관리
        </div>
      </div>
    </div>
    <div class="menu" @click="movePage('payslip.employee.index')">
      <div class="content">
        <div class="icon">
          <i class="fal fa-calculator"></i>
        </div>
        <div class="title">
          급여관리
        </div>
      </div>
    </div>
    <div class="menu" @click="movePage('invoice.index')">
      <div class="content">
        <div class="icon">
          <i class="fal fa-file-alt"></i>
        </div>
        <div class="title">
          급여지급명세서
        </div>
      </div>
    </div>
    <div class="menu" @click="movePage('my-page.index')">
      <div class="content">
        <div class="icon">
          <i class="fas fa-user"></i>
        </div>
        <div class="title">
          마이페이지
        </div>
      </div>
    </div>
    <div class="menu" @click="movePage('announcement.notice.index')">
      <div class="content">
        <div class="icon">
          <i class="fas fa-phone-square-alt"></i>
        </div>
        <div class="title">
          공지사항
        </div>
      </div>
    </div>
    <div class="menu" @click="movePage('announcement.knowhow.index')">
      <div class="content">
        <div class="icon">
          <i class="fas fa-calculator"></i>
        </div>
        <div class="title">
          세무지식
        </div>
      </div>
    </div>
    <div class="menu" @click="movePage('announcement.template.index')">
      <div class="content">
        <div class="icon">
          <i class="fas fa-file-alt"></i>
        </div>
        <div class="title">
          서식모음
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      movePage(routeName) {
        this.$router.push({
          name: routeName
        });
      }
    }
  }
</script>
