import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
// @ts-ignore
import jscookie from 'js-cookie';
import VueRouterBackButton from '../plugins/vue-router-back-button/src/index'

Vue.use(VueRouter);

import auth from './auth';
import dashboard from "@/router/dashboard";
import employee from "@/router/employee";
import mypage from "@/router/mypage";
import payslip from "@/router/payslip";
import invoice from './invoice';
import print from './print';
import announcement from "./announcement";
import terms from './terms';
import SettingView from '@/views/setting/Index.vue';

import Page404 from '@/views/errors/404.vue';
import PageNotCertified from '@/views/errors/NotCertified.vue';
import AppLayout from '@/AppLayout.vue';
import GetUserFromSessionTokenJob from "@/core/jobs/auth/GetUserFromSessionTokenJob";

import EmptyView from '@/views/Empty.vue';

const routes = [
  ...auth,
  {
    path: '/setting',
    component: SettingView,
    name: 'setting'
  },
  {
    path: '/empty',
    component: EmptyView,
    name: 'empty'
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      ...dashboard,
      ...employee,
      ...mypage,
      ...payslip,
      ...invoice,
      ...announcement,
      ...terms
    ],
    redirect: '/dashboard',
    beforeEnter(to: any, from: any, next: any) {
      if (to.name === 'terms.privacy' || to.name === 'terms.usage') {
        next();

        return;
      }
      // @ts-ignore
      if (store.state.auth.user === null) {
        const token = jscookie.get('hoyeon-session');

        if (token !== undefined) {
          // @ts-ignore
          dispatch(new GetUserFromSessionTokenJob())
            .then(() => {
              next(to);
            })
            .catch(() => {
              jscookie.delete('hoyeon-session');
              next('/auth/login');
            });
        } else {
          next('/auth/login');
        }
      }
      // @ts-ignore
      else if (! store.state.auth.user.certified) {
        next('/not-certified');
      }
      else {
        next();
      }
    }
  },
  ...print,
  {
    path: '/not-certified',
    component: PageNotCertified,
    name: 'not-certified'
  },
  {
    path: '/*',
    component: Page404
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

Vue.use(VueRouterBackButton, { router });

export default router
