<template>
  <div class="card">
    <div class="card-heading">
      <i class="fa fa-won-sign"></i> 급여 입력
    </div>
    <div class="card-body">
      <div class="row">
        <template v-for="(v, index) in value">
          <div class="col-md-3">
            <div class="form-group">
              <label :for="`property_${index}`">{{ v.name }}</label>
              <input
                type="text"
                class="form-control"
                :id="`property_${index}`"
                :value="v.value"
                @input="changed($event, index)"
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['value'],
    methods: {
      changed(ev, index) {
        const values = [...this.value];

        values[index] = {
          ...values[index],
          value: ev.target.value
        };

        this.$emit('input', values);
      }
    }
  }
</script>
