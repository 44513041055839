import {User} from "@/core/types/model";
import moment from 'moment';

export default {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    setUser(state: any, user: User | null) {
      if (user === null) {
        state.user = null;
      } else {
        state.user = {
          ...user
        };
      }
    }
  }
}
