import {DispatchContext, Job} from "@/core/types";

import * as phoneCertApi from '@/core/api/phone-cert';

export default class SendPhoneCertNumberJob implements Job {
  private readonly phoneNumber: string;

  constructor(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await phoneCertApi.issue(this.phoneNumber);
  }
}
