export default {
  namespaced: true,
  state: {
    isApp: false
  },
  mutations: {
    setIsApp(state, v) {
      state.isApp = v;
    }
  }
}
