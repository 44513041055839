<template>
  <div class="notice">
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 공지사항 목록
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover list board-list">
            <colgroup>
              <col width="70" />
              <col width="*" />
              <col width="120" />
            </colgroup>
            <thead>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>작성일</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(announcement, index) in announcements">
              <tr @click="showAnnouncement(announcement)">
                <td align="center">
                  {{ total - (per_page * (page - 1)) - index }}
                </td>
                <td>{{ announcement.title }}</td>
                <td align="center">{{ announcement.created_at }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <pagination
          :total="total"
          :page="page"
          :per-page="per_page"
          @move-page="movePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import LoadAnnouncementJob from "../../../core/jobs/announcement/LoadAnnouncementJob";
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('announcement', {
        announcements: state => state.announcements,
        page: state => state.page,
        per_page: state => state.per_page,
        total: state => state.total
      })
    },
    methods: {
      showAnnouncement(announcement) {
        this.$router.push({
          name: 'announcement.notice.show',
          params: {
            id: announcement.id
          }
        })
      },
      movePage(page) {
        dispatch(new LoadAnnouncementJob(page, 'notice'));
      }
    }
  }
</script>
