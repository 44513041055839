import {DispatchContext, Job} from "@/core/types";
import postMessage from "@/core/post-message";

export default class DownloadPayslipJob implements Job {
  private readonly year: string;
  private readonly month: string;
  private readonly employeeId: string | null = null;

  constructor(year: string, month: string, employeeId: string | null = null) {
    this.year = year;
    this.month = month;
    this.employeeId = employeeId;
  }

  async run(context: DispatchContext): Promise<any> {
    if (context.store.state.app.isApp) {
      let params = [
        `year=${this.year}`,
        `month=${this.month}`
      ];

      if (this.employeeId !== null) {
        params.push(`employee_id=${this.employeeId}`);
      }

      postMessage(JSON.stringify({
        type: 'download',
        method: 'get',
        filename: `${this.year}년${this.month}월급여명세서.pdf`,
        url: `${process.env.VUE_APP_BACKEND_URL}/api/v1/download/payslip?${params.join('&')}`,
      }));
    } else {
      const body = document.getElementsByTagName('body')[0];
      const form = document.createElement('form');

      body.appendChild(form);

      form.action = `${process.env.VUE_APP_BACKEND_URL}/download/payslip`;
      form.target = '_blank';
      form.method = 'post';

      form.appendChild(this.createHiddenInput('year', this.year));
      form.appendChild(this.createHiddenInput('month', this.month));
      form.appendChild(this.createHiddenInput('api_token', context.store.state.auth.user.api_token));

      if (this.employeeId !== null) {
        form.appendChild(this.createHiddenInput('employee_id', this.employeeId));
      }

      form.submit();
      body.removeChild(form);
    }
  }

  createHiddenInput(name: string, value: string) {
    const input = document.createElement('input');

    input.type = 'hidden';
    input.name = name;
    input.value = value;

    return input;
  }
}
