<template>
  <div>
    <div class="heading">
      <div class="logo">
        <img src="../../assets/logo/logo.png" alt="로고" width="200">
      </div>
      <h4>호연 급여관리 시스템</h4>
    </div>
    <div class="body">
      <div class="form-group">
        <label for="userid">아이디</label>
        <v-input
          type="text"
          id="userid"
          placeholder="아이디"
          v-model="userInput.userid"
          :errors="errors.userid"
          @keypress.enter="login"
        />
      </div>
      <div class="form-group">
        <label for="password">비밀번호</label>
        <v-input
          type="password"
          id="password"
          placeholder="비밀번호"
          v-model="userInput.password"
          :errors="errors.password"
          @keypress.enter="login"
        />
      </div>
    </div>
    <div class="footer">
      <div class="row">
        <div class="col-md-6">
          <div class="remember-checkbox" v-if="deviceToken === null">
            <label>
              <input type="checkbox" v-model="remember"> 기억하기
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-right">
            <router-link
              :to="{name: 'reset'}"
              class="find-password"
            >
              비밀번호 찾기
            </router-link>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-lg btn-primary btn-block" @click="login">
        로그인
      </button>
    </div>
    <div class="text-right">
      <router-link :to="{name: 'register'}" class="register-link">
        회원가입
      </router-link>
    </div>
  </div>
</template>
<script>
  import LoginJob from "@/core/jobs/auth/LoginJob";
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        userInput: {
          userid: '',
          password: ''
        },
        remember: false,
        errors: {}
      };
    },
    computed: {
      ...mapState('app', {
        deviceToken: state => state.deviceToken
      })
    },
    methods: {
      login() {
        dispatch(new LoginJob(this.userInput.userid, this.userInput.password, this.remember)).then(() => {
          this.$router.push({name: 'dashboard'});
        }).catch(err => {
          alert('아이디와 비밀번호를 확인해주세요.');
        });
      }
    }
  }
</script>
