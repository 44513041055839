<template>
  <div class="notice">

    <div class="row template-list">
      <template v-for="(announcement, index) in announcements">
        <div class="col-sm-6 col-md-4">
          <div class="template">
            <div class="title">{{ announcement.title }}</div>
            <div class="summary">
              {{ announcement.content }}
            </div>
            <div class="clearfix">
              <div class="created-at">
                {{ announcement.created_at }}
              </div>
              <div class="icons">
                <template v-for="file in announcement.files">
                  <button type="button" @click="download(file)" v-if="isApp">
                    <i class="fa fa-file-excel" v-if="file.file_type === 'xls' || file.file_type === 'xlsx'" />
                    <i class="fa fa-file-word" v-else-if="file.file_type === 'doc' || file.file_type === 'docx'" />
                    <i class="fa fa-file-powerpoint" v-else-if="file.file_type === 'ppt' || file.file_type === 'pptx'" />
                    <i class="fa fa-file-pdf" v-else-if="file.file_type === 'pdf'" />
                    <i class="fa fa-file-download" v-else />
                  </button>
                  <a :href="file.url" target="_blank" v-else>
                    <i class="fa fa-file-excel" v-if="file.file_type === 'xls' || file.file_type === 'xlsx'" />
                    <i class="fa fa-file-word" v-else-if="file.file_type === 'doc' || file.file_type === 'docx'" />
                    <i class="fa fa-file-powerpoint" v-else-if="file.file_type === 'ppt' || file.file_type === 'pptx'" />
                    <i class="fa fa-file-pdf" v-else-if="file.file_type === 'pdf'" />
                    <i class="fa fa-file-download" v-else />
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <pagination
      :total="total"
      :page="page"
      :per-page="per_page"
      @move-page="movePage"
    />
  </div>
</template>
<script>
  import LoadAnnouncementJob from "../../../core/jobs/announcement/LoadAnnouncementJob";
  import {mapState} from 'vuex';
  import postMessage from "@/core/post-message";

  export default {
    computed: {
      ...mapState('announcement', {
        announcements: state => state.announcements,
        page: state => state.page,
        per_page: state => state.per_page,
        total: state => state.total
      }),
      ...mapState('app', {
        isApp: state => state.isApp
      })
    },
    methods: {
      movePage(page) {
        dispatch(new LoadAnnouncementJob(page, 'template')).then(() => {
          $(window).scrollTop(0);
        });
      },
      download(file) {
        postMessage(JSON.stringify({
          type: 'download',
          method: 'get',
          filename: file.name,
          url: file.url,
          data: {}
        }));
      }
    }
  }
</script>
