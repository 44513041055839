<template>
  <div class="alarm-card">
    <div class="card-heading">
      세무지식
    </div>
    <div class="card-body">
      <table class="announcements">
        <tbody>
        <template v-for="knowhow in knowhows">
          <tr>
            <td class="date">{{ knowhow.created_at }}</td>
            <td class="text">
              <router-link
                :to="{name: 'announcement.knowhow.index', query: {id: knowhow.id}}"
              >
                {{ knowhow.title }}
              </router-link>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
      <div class="text-right">
        <router-link
          :to="{name: 'announcement.knowhow.index'}"
          class="btn btn-primary"
        >
          <i class="fal fa-plus"></i> 더보기
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import * as announcementApi from '@/core/api/announcement';

  export default {
    data() {
      return {
        knowhows: []
      };
    },
    mounted() {
      announcementApi.getAnnouncements(0, 'knowhow').then(response => {
        this.knowhows = response.data.data.data.slice(0, 10);
      });
    }
  }
</script>
