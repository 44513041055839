import InvoiceIndexView from '@/views/invoice/Index.vue';
import InvoiceDetailView from '@/views/invoice/Detail.vue';

export default [
  {
    path: '/invoice',
    component: InvoiceIndexView,
    name: 'invoice.index'
  },
  {
    path: '/invoice/:id',
    component: InvoiceDetailView,
    name: 'invoice.detail'
  }
];
