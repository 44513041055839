<template>
  <div>
    <employee-form
      v-model="userInput"
      :errors="errors"
      :is-update="true"
    />
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-hands-helping"></i> 피부양자
      </div>
      <div class="card-body">
        <dependent-form
          v-model="dependents"
        />
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-file-upload"></i>가족관계증명서
      </div>
      <div class="card-body">
        <div class="form-group">
          <v-input-file
            id="dependent_file"
            v-model="dependentFile"
          />
        </div>
        <a v-if="employee.dependent_file !== null" :href="employee.dependent_file" target="_blank" class="btn btn-default">다운로드</a>
      </div>
    </div>
    <div class="card" v-if="employee.in_completed === true && employee.employee_type === '일반근로자'">
      <div class="card-heading">
        <i class="fa fa-scroll"></i> 입사 정보
      </div>
      <div class="card-body">

        <template v-if="employee.regist_file !== null">
          <button type="button" @click="downloadFile(employee.regist_file, '취득신고서')" class="btn btn-primary" v-if="isApp">
            취득신고서
          </button>
          <a :href="employee.regist_file" class="btn btn-primary" v-else>
            취득신고서
          </a>
        </template>
      </div>
    </div>
    <div class="card" v-if="employee.out_completed === true && employee.employee_type === '일반근로자'">
      <div class="card-heading">
        <i class="fa fa-sign-out"></i> 퇴사 정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>퇴직금</label>
              <input type="text" class="form-control" :value="employee.severance_pay" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>소득세</label>
              <input type="text" class="form-control" :value="employee.income_tax" readonly>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>주민세</label>
              <input type="text" class="form-control" :value="employee.jumin_tax" readonly>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>기타공제</label>
              <input type="text" class="form-control" :value="employee.etc_exclude" readonly>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>차인지급액</label>
              <input type="text" class="form-control" :value="employee.final_payment" readonly>
            </div>
          </div>
        </div>
        <div>
          <template v-if="employee.out_file !== null">
            <button type="button" class="btn btn-primary" @click="downloadFile(employee.out_file, '퇴직소득원천징수영수증')" v-if="isApp">
              퇴직소득원천징수영수증
            </button>
            <a :href="employee.out_file" target="_blank" class="btn btn-primary" v-else>
              퇴직소득원천징수영수증
            </a>
          </template>
          &nbsp;
          <template v-if="employee.out_file2 !== null">
            <button type="button" class="btn btn-primary" @click="downloadFile(employee.out_file2, '상실신고서')" v-if="isApp">
              상실신고서
            </button>
            <a :href="employee.out_file2" target="_blank" class="btn btn-primary" v-else>
              상실신고서
            </a>
          </template>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary" @click="update">
        확인
      </button>
      &nbsp;
      <button type="button" class="btn btn-default" @click="$router.back()">
        목록
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import EmployeeForm from "@/components/employee/manage/EmployeeForm";
  import UpdateEmployeeJob from "@/core/jobs/employee/UpdateEmployeeJob";
  import DependentForm from "@/components/employee/manage/DependentForm";
  import postMessage from "@/core/post-message";

  export default {
    data() {
      return {
        dependentFile: null,
        dependents: [
        ],
        userInput: {
          code: '',
          name: '',
          birthday: '',
          position: '',
          department: '',
          employee_type: '',
          pay_type: '',
          jumin: '',
          role: '',
          is_contract: false,
          email: '',
          contract_from: '',
          contract_to: ''
        },
        errors: {}
      };
    },
    methods: {
      downloadFile(url, name) {
        const extension = url.split('.').pop();

        postMessage(JSON.stringify({
          type: 'download',
          method: 'get',
          filename: `${name}.${extension}`,
          url
        }));
      },
      update() {
        dispatch(new UpdateEmployeeJob(parseInt(this.$route.params.id), {
          name: this.userInput.name,
          birthday: this.userInput.birthday,
          position: this.userInput.position,
          department: this.userInput.department,
          employee_type: this.userInput.employee_type,
          pay_type: this.userInput.pay_type,
          jumin: this.userInput.jumin,
          working_hour: this.userInput.working_hour,
          income: this.userInput.income,
          dependents: this.dependents,
          is_contract: this.userInput.is_contract,
          role: this.userInput.role,
          phone_number: this.userInput.phone_number,
          nation: this.userInput.nation,
          stay_condition: this.userInput.stay_condition,
          dependent_file: this.dependentFile,
          email: this.userInput.email,
          contract_from: this.userInput.contract_from,
          contract_to: this.userInput.contract_to
        })).then(() => {
          this.dependentFile = null;

          alert('정보수정이 완료되었습니다.');
          this.errors = {};
        }).catch(err => {
          if (err.response.status === 422) {
            alert('필수 입력사항이 누락되었습니다. 입력사항을 확인해 주세요.');
            this.errors = err.response.data;
          }
        });
      }
    },
    computed: {
      ...mapState('employee', {
        employee: state => state.selected_employee
      }),
      ...mapState('app', {
        isApp: state => state.isApp
      })
    },
    components: {
      EmployeeForm,
      DependentForm
    },
    mounted() {
      this.userInput = {
        code: this.employee.code,
        name: this.employee.name,
        birthday: this.employee.birthday,
        position: this.employee.position,
        department: this.employee.department,
        employee_type: this.employee.employee_type,
        pay_type: this.employee.pay_type,
        jumin: this.employee.jumin,
        working_hour: this.employee.working_hour,
        income: this.employee.income,
        role: this.employee.role,
        is_contract: this.employee.is_contract,
        phone_number: this.employee.phone_number,
        nation: this.employee.nation,
        stay_condition: this.employee.stay_condition,
        email: this.employee.email,
        contract_from: this.employee.contract_from,
        contract_to: this.employee.contract_to
      };

      this.dependents = [
        ...this.employee.dependents
      ];
    }
  }
</script>
