<template>
  <div class="container">
    <h1 class="page-title">공지사항</h1>

    <notice-show />
  </div>
</template>
<script>
  import NoticeShow from "@/components/announcement/notice/NoticeShow";
  import SelectAnnouncementJob from "@/core/jobs/announcement/SelectAnnouncementJob";

  export default {
    components: {
      NoticeShow
    },
    mounted() {
      dispatch(new SelectAnnouncementJob(parseInt(this.$route.params.id)));
    }
  }
</script>
