import * as http from './http';

export function getAnnouncements(page: number, type: string) {
  return http.get(`/api/v1/announcement/${type}?page=${page}`);
}

export function findAnnouncement(id: number | null, type: string) {
  if (id === null) {
    return http.get(`/api/v1/announcement/${type}/get`);
  } else {
    return http.get(`/api/v1/announcement/${type}/get/${id}`);
  }
}
