<template>
  <div class="container">
    <div class="cs-center-contact">
      <h3 class="page-title">고객센터 연락처</h3>
      <div class="row">
        <div class="col-md-4">
          <div class="card-cs-center">
            <div class="card-body">
              <i class="fal fa-envelope"></i>
              <h4>우편 보내실 주소</h4>

              서울특별시 강남구 영동대로 611, 찬앤찬타워 9층<br />
              <br /><br />

              <i class="fa fa-print"></i>
              <h4>FAX | <span class="highlight">02-6205-4069</span></h4>
              <h4>대표번호 | <span class="highlight">02-6205-4070</span></h4>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-cs-center">
            <div class="card-body">
              <h4>급여관련 문의</h4>
              <br />
              <table width="100%">
                <colgroup>
                  <col width="30%" />
                  <col width="70%" />
                </colgroup>
                <tr v-for="cscenter in cscenterList">
                  <td valign="top" class="name"><strong>{{ cscenter.name }}</strong></td>
                  <td align="left" class="info">
                    <span class="tel">{{ cscenter.phone_number }}</span><br />
                    {{ cscenter.email }}
                  </td>
                </tr>
                <!--
                <tr>
                  <td valign="top" class="name"><strong>진다영 과장</strong></td>
                  <td align="left" class="info">
                    <span class="tel">02-3445-9702</span><br />
                    dyjin@ihoyeonacc.com
                  </td>
                </tr>
                <tr>
                  <td valign="top" class="name"><strong>황혜림 대리</strong></td>
                  <td align="left" class="info">
                    <span class="tel">02-6953-0901</span><br />
                    hlhwang@ihoyeonacc.com
                  </td>
                </tr>
                <tr>
                  <td valign="top" class="name"><strong>문혜란 주임</strong></td>
                  <td align="left" class="info">
                    <span class="tel">02-3445-9877</span><br />
                    hrmoon@ihoyeonacc.com
                  </td>
                </tr>
                -->
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-cs-center">
            <div class="card-body">
              <h4>페이호연 시스템 문의</h4>

              <br />
              <table width="100%">
                <colgroup>
                  <col width="30%" />
                  <col width="70%" />
                </colgroup>
                <tr>
                  <td valign="top" class="name"><strong>최학수 회계사</strong></td>
                  <td align="left" class="info">
                    <span class="tel">02-6205-4089</span><br />
                    hschoi@ihoyeonacc.com
                  </td>
                </tr>
                <tr>
                  <td valign="top" class="name">
                    <strong>상담 가능시간</strong>
                  </td>
                  <td align="left" class="info">
                    평일 9:00 ~ 17:00<br />
                    (점심시간 12:00 ~ 13:00)<br />
                    주말/공휴일 휴무
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="page-title">공지사항</h1>
    <notice-list />
  </div>
</template>
<script>
  import NoticeList from "@/components/announcement/notice/NoticeList";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";
  import * as configApi from '@/core/api/config';

  export default {
    data() {
      return {
        cscenterList: []
      };
    },
    methods: {
      loadConfigs() {
        configApi.get('cscenter').then(response => {
          this.cscenterList = response.data.map(d => JSON.parse(d.value));
        });
      }
    },
    components: {
      NoticeList
    },
    mounted() {
      this.loadConfigs();
      dispatch(new LoadAnnouncementJob(0, 'notice'));
    }
  }
</script>
