<template>
  <div>
    <banner />
    <div class="container">
      <menu-icon />
      <div class="row">
        <div class="col-md-6">
          <my-alarm />
        </div>
        <div class="col-md-6">
          <announcements />
          <knowhow />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Banner from "@/components/dashboard/Banner";
  import MenuIcon from "@/components/dashboard/MenuIcon";
  import MyAlarm from "@/components/dashboard/MyAlarm";
  import Announcements from "@/components/dashboard/Announcements";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";
  import LoadUserAlarmJob from "@/core/jobs/main/LoadUserAlarmJob";
  import Knowhow from "@/components/dashboard/Knowhow";

  export default {
    mounted() {
      dispatch(new LoadAnnouncementJob(0, 'notice'));
      dispatch(new LoadUserAlarmJob());
    },
    components: {
      Banner,
      MenuIcon,
      MyAlarm,
      Announcements,
      Knowhow
    }
  }
</script>
