<template>
  <tr>
    <td align="center">{{ employee.in_date }}</td>
    <td align="center">{{ employee.position }}</td>
    <template v-for="(num, index) in 7">
      <td align="right" :class="`2-${num}`">{{ wages.length > (index + 7) ? wages[index + 7].value.format() : '' }}</td>
    </template>
    <template v-for="(num, index) in 5">
      <td align="right" :class="`2-${num + 7}`">{{ excludes.length > (index + 6) ? excludes[index + 6].value.format() : '' }}</td>
    </template>
    <td align="right" class="2-13">{{ totalExcludes.format() }}</td>
  </tr>
</template>
<script>
  export default {
    props: ['employee', 'payslip'],
    computed: {
      wages() {
        return this.employee.payslip_values.filter(v => v.type === 'wage' && v.name !== '근무시간');
      },
      excludes() {
        return this.employee.payslip_values.filter(v => v.type === 'exclude');
      },
      totalExcludes() {
        return this.excludes.reduce((a, b) => a + b.value, 0);
      }
    }
  }
</script>
