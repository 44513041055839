import {DispatchContext, Job} from "@/core/types";
import {resetPassword, ResetPasswordData} from "@/core/api/auth";

export default class ResetPasswordJob implements Job {
  private readonly data: ResetPasswordData;

  constructor(data: ResetPasswordData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await resetPassword(this.data);
  }
}
