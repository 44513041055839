import {DispatchContext, Job} from "@/core/types";
import {InputWageData, inputWage} from "@/core/api/payslip";

export default class InputWageJob implements Job {
  private readonly data: InputWageData;

  constructor(data: InputWageData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await inputWage(this.data);
  }
}
