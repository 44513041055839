<template>
  <div>
    <div class="heading">
      <div class="logo">
        <img src="../../assets/logo/logo.png" alt="로고" width="200">
      </div>
      <h4>비밀번호 찾기</h4>
    </div>
    <div class="body">
      <div class="form-group">
        <label for="email">이메일</label>
        <v-input
          type="email"
          id="email"
          placeholder="이메일"
          v-model="userInput.email"
          :errors="errors.email"
        />
      </div>
    </div>
    <div class="footer">
      <button type="button" class="btn btn-primary btn-block" @click="sendEmail">
        이메일 전송
      </button>
    </div>
    <div class="text-right">
      <router-link :to="{name: 'login'}" class="register-link">
        로그인
      </router-link>
    </div>
  </div>
</template>
<script>
  import SendPasswordResetLinkJob from "@/core/jobs/auth/SendPasswordResetLinkJob";

  export default {
    data() {
      return {
        userInput: {
          email: ''
        },
        errors: {}
      };
    },
    methods: {
      sendEmail() {
        dispatch(new SendPasswordResetLinkJob(this.userInput.email))
          .then(() => {
            alert('이메일로 비밀번호 변경 링크를 전송하였습니다.');
          }).catch(err => {
            if (err.response.status === 422) {
              this.errors = err.response.data.errors;
            } else {
              alert('잘못된 이메일 주소를 입력하셨습니다.');
            }
          });
      }
    }
  }
</script>
