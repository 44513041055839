<template>
  <div>
    <div class="notice board-show" v-if="announcement !== null">
      <div class="notice-header">
        작성자 : 관리자 <span class="separator">|</span> 작성일 : {{ announcement.created_at }}
      </div>
      <div class="notice-body">
        <h3>{{ announcement.title }}</h3>
        <div v-html="announcement.content"></div>
      </div>
      <div class="files" v-if="announcement.files.length > 0">
        <h5>첨부파일</h5>
        <template v-for="file in announcement.files">
          <div class="file">
            <button type="button" @click="download(file)" v-if="isApp">
              <i class="fa fa-download"></i> {{ file.name }}
            </button>
            <a :href="file.url" target="_blank" v-else><i class="fa fa-download"></i> {{ file.name }}</a>

          </div>
        </template>
      </div>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-default" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import postMessage from "@/core/post-message";

  export default {
    computed: {
      ...mapState('announcement', {
        announcement: state => state.selectedAnnouncement
      }),
      ...mapState('app', {
        isApp: state => state.isApp
      })
    },
    methods: {
      download(file) {
        postMessage(JSON.stringify({
          type: 'download',
          method: 'get',
          filename: file.name,
          url: file.url,
          data: {}
        }));
      }
    }
  }
</script>
