import {DispatchContext, Job} from "@/core/types";
import {getPayslip} from "@/core/api/payslip";
import GetAttachmentsJob from "@/core/jobs/payslip/GetAttachmentsJob";

export default class GetPayslipJob implements Job {
  private readonly year: string;
  private readonly month: string;

  constructor(year: string, month: string) {
    this.year = year;
    this.month = month;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getPayslip({
      year: this.year,
      month: this.month
    });

    context.store.commit('invoice/setPayslip', {
      year: this.year,
      month: this.month,
      data: response.data.data
    });

    // @ts-ignore
    return dispatch(new GetAttachmentsJob(this.year, this.month));
  }
}
