<template>
  <div class="container">
    <h1 class="page-title">
      개인정보처리방침
    </h1>
    <pre>제 1 조 (총칙) ① 개인정보란 이용자 개인에 관한 정보를 칭한다. 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 개인을 식별할 수 있는 정보를 지칭한다.
② 호연회계법인은 이러한 모든 개인정보를 「개인정보보호법」 등 관련 법령상의 개인정보보호 규정을 준수하며 수집•보유•처리한다.
③ 호연회계법인은 「개인정보보호법」에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있으며, 개인정보처리방침을 변경하는 경우에는 시행의 시기, 변경된 내용을 정보주체가 쉽게 확인할 수 있도록 변경 전•후를 비교하여 투명하게 공개할 예정이다.

제 2 조 (개인정보 수집범위) 호연회계법인의 페이호연(Payhoyeon) 서비스를 이용할 경우 다음의 정보를 전달해야 한다.
1) 회원가입 시 수집하는 개인정보의 범위
- ID, 비밀번호, 성명, 전자우편 주소
2) 서비스 이용시 수집하는 개인정보의 범위
- 주민등록번호, 계좌번호, 전화번호, 주소, 홈택스 및 CMS 수임동의와 관련된 항목
3) 개인/사업자/범용 공인인증서
4) 이용자가 소유하고 있는 신용카드, 체크카드사의 사이트 회원정보

제 3 조 (개인정보의 열람, 정정) 이용자는 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 정정할 수 있다. 개인정보 열람 및 정정을 하고자 할 경우에는 직접 열람 또는 정정하거나, 운영자에게 전화 또는 전자우편으로 연락하면 운영자는 지체 없이 조치한다.

제 4 조 (개인정보 수집, 이용, 제공에 대한 동의 철회) 개인정보의 수집, 이용, 제공에 대해 이용자는 동의한 내용을 언제든지 철회 할 수 있다. 동의철회는 직접 철회하거나, 관리자에게 유, 무선으로 연락하면 관리자는 즉시 개인정보의 삭제 등 필요한 조치를 한다.

제 5 조 (개인정보의 수집목적 및 이용목적) 다음과 같은 목적을 위하여 개인정보를 수집하고 있다.
1) ID, 비밀번호, 성명, 주민등록번호 : 회원제 서비스 이용에 따른 본인 식별 절차에 이용
2) 주소, 전화번호, 전자우편 주소 : 서비스 제공에 있어서의 원활한 커뮤니케이션 수단 확보, 중요고지사항의 전달, 회사가 제공하는 모든 상품/서비스, 제휴사에서 제공하는 상품/서비스 및 회사가 타사로부터 의뢰 받은 타사의 상품/서비스에 대해 전화, SMS, MMS, e-mail, 우편 등을 통해 정보/광고의 전송 제공 목적
3) 기타 홈택스 및 CMS 관련 정보 : 수임동의 및 원활한 회계세무 대행서비스를 위한 수단, 신규 서비스 개발 및 이용자별 특화서비스 제공을 위한 통계분석

제 6 조 (목적 외 사용 및 제3자에 대한 제공 및 공유) ① 이용자의 개인정보를 	&lt;개인정보의 수집목적 및 이용목적&gt;에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업•기관에 제공하지 않는다.
② 고지 및 동의방법은 온라인 홈페이지 초기화면의 공지사항을 통해 최소 30일 이전부터 고지함과 동시에 e-mail 등을 이용하여 1회 이상 개별적으로 고지하고 매각•인수합병에 대해서는 반드시 적극적인 동의 방법(개인정보의 제3자 제공 및 공유에 대한 의사를 직접 밝힘)에 의해서만 절차를 진행한다.
③ 다음은 예외로 한다.
1) 관계법령에 의하여 수사상의 목적으로 관계기관으로부터의 요구가 있을 경우
2) 통계작성•학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주•협력사나 연구단체 등에 제공하는 경우
3) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
4) 회사는 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에 의해 정보를 제공한
경우에는 이를 당사자에게 고지하는 것을 원칙으로 한다. 그러나 회사는 법률상의 근거에 의해 부득이하게 고지를 하지 못할 수도 있다. 회사는 본래의 수집목적 및 이용목적에 반하여 무분별하게 정보가 제공되지 않도록 최대한 노력한다.

제 7 조 (고객정보의 보유 및 이용기간) 호연회계법인이 이용자들로부터 개인정보를 수집하는 경우 그 보유기간 및 이용기간은 이용자가 직접 회원 가입을 신청한 시점부터 해지하겠다는 의사 표시를 하여 해지가 처리된 시점까지로 한다.

제 8 조 (고객정보의 보호 및 관리) ① 호연회계법인의 고객개인정보는 사용자 ID와 비밀번호로 보호된다. 사용자 정보는 본인만이 직접 관리할 수 있다. 정보 변경은 반드시 본인 확인 과정을 거쳐 정보를 편집할 수 있도록 시스템적으로 처리되어 있으므로 반드시 회원은 사용자 비밀번호를 직접 관리해야 한다. PC방이나 도서관 등과 같은 공공장소에서 컴퓨터를 이용하는 경우에는 특히 개인정보가 유출되지 않도록 유의해야 한다. 단, 인터넷을 통한 데이터 전송에 대한 보안성은 호연회계법인에서 시스템적으로 최선을 다하여 보호하지만, 회원들도 개인정보 보호를 위하여 만전을 기해야 한다.
② 호연회계법인은 회원의 개인정보를 보호하기 위하여 운영상의 시정 또는 개선을 요구하는 사항이 있는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하도록 최선을 다한다.

제 9 조 (이용자의 권리와 의무) ① 이용자는 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방 해야 한다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있다.
② 이용자는 개인정보를 보호 받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있다. 비밀번호를 포함한 회원의 개인정보가 유출되니 않도록 조심해야 하고 타인의 개인정보를 훼손하지 않도록 유의해야 한다. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 「정보통신망이용촉진및정보보호등에관한법률」등에 의해 처벌받을 수 있다.

제 10 조 (고지의 의무) 현 개인정보보호정책은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가•삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지를 통해 고지한다.

제 11 조 (개인정보관리책임자) ① 호연회계법인은 개인정보의 누출 및 오•남용등으로 인한 피해를 방지하기 위하여 개인정보취급자를 최소한의 인원으로 제한하고 있으며, 개인정보 관리에 관한 실질적인 책임을 가진 대표자를 개인정보관리책임자로 지정하고 있다.
② 호연회계법인의 개인정보관리책임자는 다음과 같다.

성명 : 최학수
소속 : 호연회계법인
전화 : 02-6929-0944
전자우편 : hschoi@ihoyeonacc.com


개인정보보호정책 최초제정일자 : 2019-10-31
    </pre>
  </div>
</template>
<script>
  export default {

  }
</script>
