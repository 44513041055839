import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

import auth from './auth';
import employee from './employee';
import payslip from './payslip';
import invoice from './invoice';
import announcement from './announcement';
import main from './main';
import app from './app';

export default new Vuex.Store({
  state: {
    isLoading: false
  },
  mutations: {
    setIsLoading(state, v) {
      state.isLoading = v;
    }
  },
  modules: {
    auth,
    employee,
    payslip,
    invoice,
    announcement,
    main,
    app
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
