import Page from '@/views/employee/Page.vue';

import ManageIndexView from '@/views/employee/manage/Index.vue';
import ManageCreateView from '@/views/employee/manage/Create.vue';
import ManageEditView from '@/views/employee/manage/Edit.vue';
import ManageLeaveView from '@/views/employee/manage/Leave.vue';

export default [
  {
    path: '/employee',
    component: Page,
    children: [
      {
        path: 'manage',
        component: ManageIndexView,
        name: 'employee.manage.index'
      },
      {
        path: 'manage/create',
        component: ManageCreateView,
        name: 'employee.manage.create'
      },
      {
        path: 'manage/:id/edit',
        component: ManageEditView,
        name: 'employee.manage.edit'
      },
      {
        path: 'manage/:id/leave',
        component: ManageLeaveView,
        name: 'employee.manage.leave'
      }
    ]
  }
];
