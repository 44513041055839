import {DispatchContext, Job} from "@/core/types";
import postMessage from "@/core/post-message";

export default class DownloadPayrollJob implements Job {
  private readonly month: string;
  private readonly year: string;
  private readonly type: string;

  constructor(year: string, month: string, type: string) {
    this.year = year;
    this.month = month;
    this.type = type;
  }

  async run(context: DispatchContext): Promise<any> {
    if (context.store.state.app.isApp) {
      let params = [
        `year=${this.year}`,
        `month=${this.month}`,
        `type=${this.type}`
      ];

      postMessage(JSON.stringify({
        type: 'download',
        method: 'get',
        filename: `${this.year}년${this.month}월급여대장.xlsx`,
        url: `${process.env.VUE_APP_BACKEND_URL}/api/v1/download/payroll?${params.join('&')}`
      }));
    } else {
      const body = document.getElementsByTagName('body')[0];
      const form = document.createElement('form');

      body.appendChild(form);

      form.action = `${process.env.VUE_APP_BACKEND_URL}/download/payroll`;
      form.target = '_blank';
      form.method = 'post';

      form.appendChild(this.createHiddenInput('year', this.year));
      form.appendChild(this.createHiddenInput('month', this.month));
      form.appendChild(this.createHiddenInput('type', this.type));
      form.appendChild(this.createHiddenInput('api_token', context.store.state.auth.user.api_token));
      form.submit();

      body.removeChild(form);
    }
  }

  createHiddenInput(name: string, value: string) {
    const input = document.createElement('input');

    input.type = 'hidden';
    input.name = name;
    input.value = value;

    return input;
  }
}
