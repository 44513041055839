<template>
  <div class="container">
    <h1 class="page-title">퇴사정보 입력</h1>
    <leave-form />
  </div>
</template>
<script>
  import LeaveForm from "@/components/employee/manage/LeaveForm";
  import SelectEmployeeJob from "@/core/jobs/employee/SelectEmployeeJob";

  export default {
    data() {
      return {
        loaded: false
      };
    },
    components: {
      LeaveForm
    },
    mounted() {
      dispatch(new SelectEmployeeJob(parseInt(this.$route.params.id))).then(() => {
        this.loaded = true;
      });
    }
  }
</script>
