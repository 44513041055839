import * as http from './http';

export interface GetPayslipEmployeesData {
  type: string,
  year: string,
  month: string
}

interface BasePropertyData {
  id: number,
  value: number
}

export interface InputWageData {
  employee_id: number,
  properties: Array<BasePropertyData>
  year: string,
  month: string
}

export interface FindPayslipData {
  year: string,
  month: string
}

export interface DownloadPayslipData {
  year: string,
  month: string,
  employee_id: number | null
}

export interface DownloadPayrollData {
  year: string,
  month: string
}

export interface GetAttachmentData {
  year: string,
  month: string
}

export interface ConfirmPayslipData {
  type: string,
  year: string,
  month: string,
  reason: string
}

export interface SubmitPayslipData {
  year: string,
  month: string
}

export function cancelRequest(year: string, month: string) {
  return http.post('/api/v1/payslip/cancel', {
    year,
    month
  });
}

export function submit(data: SubmitPayslipData) {
  return http.post('/api/v1/payslip/submit', data);
}

export function getAttachments(data: GetAttachmentData) {
  return http.get(`/api/v1/payslip/attachment?year=${data.year}&month=${data.month}`);
}

export function getPayslip(data: FindPayslipData) {
  return http.get(`/api/v1/payslip?year=${data.year}&month=${data.month}`);
}

export function getEmployeePayslip(employeeId: any, year: string, month: string) {
  return http.get(`/api/v1/payslip/employee/${employeeId}?year=${year}&month=${month}`);
}

export function getEmployees(page: number, data: GetPayslipEmployeesData) {
  return http.get(`/api/v1/payslip/employees?page=${page}&type=${data.type}&year=${data.year}&month=${data.month}`);
}

export function getWageProperties() {
  return http.get('/api/v1/payslip/wages');
}

export function inputWage(data: InputWageData) {
  return http.post('/api/v1/payslip/input', data);
}

export function downloadPayslip(data: DownloadPayslipData) {
  let params: Array<string> = [];

  params.push(`year=${data.year}`);
  params.push(`month=${data.month}`);
  if (data.employee_id !== null) {
    params.push(`employee_id=${data.employee_id}`);
  }

  return http.get(`/api/v1/payslip/download/payslip?${params.join('&')}`);
}

export function downloadPayroll(data: DownloadPayrollData) {
  return http.get(`/api/v1/payslip/download/payroll?year=${data.year}&month=${data.month}`);
}

export function confirmPayslip(data: ConfirmPayslipData) {
  return http.post('/api/v1/payslip/confirm', data);
}

export function sendEmail(employeeId: number, year: string, month: string) {
  return http.post('/api/v1/payslip/send-email', {
    employee_id: employeeId,
    year,
    month
  });
}

export function sendEmails(employeeIdList: Array<number>, year: string, month: string) {
  return http.post('/api/v1/payslip/send-emails', {
    employee_id: employeeIdList,
    year,
    month
  });
}