import {PaginateData} from "@/core/types";
import {BaseProperty, Employee} from "@/core/types/model";

export default {
  namespaced: true,
  state: {
    payslip: null,
    employees: [],
    total: 0,
    per_page: 0,
    page: 0,
    selectedEmployee: null,
    wage_properties: []
  },
  getters: {
    payslipEditable(state: any) {
      if (state.payslip === null) {
        return true;
      }

      if (state.payslip.state === 'pending') {
        return true;
      }

      return false;
    }
  },
  mutations: {
    setPayslip(state: any, data: any) {
      if (data === undefined) {
        state.payslip = null;
      } else {
        state.payslip = {
          ...data
        };
      }
    },
    setEmployees(state: any, data: PaginateData) {
      state.employees = [
        ...data.data
      ];
      state.total = data.total;
      state.per_page = data.per_page;
      state.page = data.page;
    },
    setWageProperties(state: any, data: Array<BaseProperty>) {
      state.wage_properties = [
        ...data
      ];
    },
    setSelectedEmployee(state: any, data: Employee) {
      state.selectedEmployee = {
        ...data
      };
    }
  }
}
