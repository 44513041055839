<template>
  <div>
    <div class="visible-xs visible-sm">
      <header class="app-header">
        <div class="container">
          <div class="logo">
            <router-link to="/">
              <img src="../../assets/logo/logo.png" alt="로고" width="180">
            </router-link>
          </div>
          <div class="pull-right">
            <button type="button" class="btn btn-link btn-hamburger" @click="sideOpen = !sideOpen">
              <i class="fa fa-bars"></i>
            </button>
          </div>
        </div>
      </header>
      <aside class="side-menu" :class="{on: sideOpen}">
        <div class="aside-header">
          <div class="text-right">
            <button type="button" class="btn btn-link btn-logout" @click="logout">
              <i class="fal fa-power-off"></i>
            </button>
            <button type="button" class="btn btn-link btn-hamburger" @click="sideOpen = !sideOpen">
              <i class="fal fa-bars"></i>
            </button>
          </div>

          <ul class="aside-menus">
            <template v-for="(menu, index) in menus">
              <li :key="`mobile-menu-${index}`">
                <router-link
                  :to="{name: menu.routeName}"
                >
                  <i :class="menu.icon"></i>
                  {{ menu.label }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </aside>
      <div class="side-menu-bg" :class="{on: sideOpen}" @click.prevent="sideOpen = false">
      </div>
    </div>
    <div class="hidden-xs hidden-sm">
      <header class="app-header">
        <div class="container">
          <div class="logo">
            <router-link to="/">
              <img src="../../assets/logo/logo.png" alt="로고" width="180">
            </router-link>
          </div>
          <ul class="left-menu">
            <template v-for="(menu, index) in menus">
              <li :key="`pc-menus-${index}`">
                <router-link
                  :to="{name: menu.routeName}" active-class="active"
                >
                  {{ menu.label }}
                </router-link>
              </li>
            </template>
          </ul>
          <ul class="right-menu">
            <li>
              <a href="javascript:;" @click="logout">로그아웃</a>
            </li>
          </ul>
        </div>
      </header>
    </div>
  </div>
</template>
<script>
  import LogoutJob from "@/core/jobs/auth/LogoutJob";
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        pushEnabled: false,
        sideOpen: false,
        menus: [
          {
            icon: 'fa fa-user',
            routeName: 'employee.manage.index',
            label: '사원관리'
          },
          {
            icon: 'fa fa-money-check-edit',
            routeName: 'payslip.employee.index',
            label: '급여관리'
          },
          {
            icon: 'fa fa-receipt',
            routeName: 'invoice.index',
            label: '급여지급명세서'
          },
          {
            icon: 'fa fa-cog',
            routeName: 'my-page.index',
            label: '마이페이지'
          },
          {
            icon: 'fa fa-phone-square-alt',
            routeName: 'announcement.notice.index',
            label: '공지사항'
          },
          {
            icon: 'fa fa-calculator',
            routeName: 'announcement.knowhow.index',
            label: '세무지식'
          },
          {
            icon: 'fa fa-file-alt',
            routeName: 'announcement.template.index',
            label: '서식모음'
          }
        ]
      };
    },
    watch: {
      '$route': function () {
        this.sideOpen = false;
      }
    },
    computed: {
      ...mapState('app', {
        isApp: state => state.isApp
      })
    },
    methods: {
      logout() {
        dispatch(new LogoutJob()).then(() => {
          if (!this.isApp) {
            this.$router.push({
              name: 'login'
            });
          }
        });
      },
      togglePush() {

      },
      checkPushEnabled() {

      }
    },
    mounted() {
      if (this.deviceToken !== null) {
        this.checkPushEnabled();
      }
    }
  }
</script>
