<template>
  <div class="invoice-header">
    <div class="row">
      <div class="col-md-8">
        <div class="header-info">
          <h5>{{ year }}년 {{ month }}월</h5>
          <h4>
            <template v-if="payslip !== null">
              <template v-if="payslip.state === 'user_rejected'">
                <span class="highlight">[ 수정 요청중 ]</span>
              </template>
              <template v-else-if="payslip.state === 'finished'">
                <span class="highlight">[ 확정 ]</span>
              </template>
            </template>
            급여지급명세서
          </h4>
          <div class="pay-date" v-if="payslip !== null">
            <span>
              급여지급일 : {{ payslip.pay_date }},
            </span>
            <span>
              총 급여 : {{ (payslip.total_wages - payslip.total_excludes).format() }}원
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="header-date-select">
          <div class="form-inline">
            <div class="form-group">
              <select class="form-control" v-model="userInput.year">
                <template v-for="index in (maxYear - 2010)">
                  <option :value="2010 + index">{{ 2010 + index }}년</option>
                </template>
              </select>
            </div>
            &nbsp;
            <div class="form-group">
              <select class="form-control" v-model="userInput.month">
                <template v-for="m in months">
                  <option :value="m">{{ m }}월</option>
                </template>
              </select>
            </div>
            &nbsp;
            <button type="button" class="btn btn-primary" @click="change">
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState, mapGetters} from 'vuex';
  import moment from "moment";

  export default {
    props: ['year', 'month'],
    data() {
      return {
        userInput: {
          year: 0,
          month: 0
        },
        months: [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
        ]
      }
    },
    computed: {
      maxYear() {
        return moment().format('YYYY');
      },
      ...mapState('invoice', {
        payslip: state => state.payslip
      })
    },
    watch: {
      year(v) {
        this.userInput.year = v;
      },
      month(v) {
        this.userInput.month = v;
      }
    },
    methods: {
      change() {
        this.$emit('change', {
          year: this.userInput.year,
          month: this.userInput.month
        })
      }
    },
    mounted() {
      this.userInput.year = this.year;
      this.userInput.month = this.month;
    }
  }
</script>
