<template>
  <div class="container" v-if="loaded">
    <h1 class="page-title">{{ year }}년 {{ month }}월 사업소득자 급여 입력</h1>
    <payslip-input-form />
  </div>
</template>
<script>
  import PayslipInputForm from '@/components/payslip/business/payslip-form/Index';
  import SelectEmployeeJob from "@/core/jobs/payslip/SelectEmployeeJob";

  export default {
    data() {
      return {
        loaded: false,
        year: 0,
        month: 0
      };
    },
    mounted() {
      dispatch(new SelectEmployeeJob(parseInt(this.$route.params.id))).then(() => {
        this.year = parseInt(this.$route.query.year);
        this.month = parseInt(this.$route.query.month);
        this.loaded = true;
      });
    },
    components: {
      PayslipInputForm
    }
  }
</script>
