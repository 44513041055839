export default {
  namespaced: true,
  state: {
    alarms: []
  },
  mutations: {
    setAlarms(state: any, data: any) {
      state.alarms = [
        ...data
      ];
    }
  }
}
