<template>
  <div class="row">
    <div class="col-md-9">
      <div class="row">
        <template v-for="(v, index) in value.slice(0, value.length - 5)">
          <div class="col-md-6">
            <div class="form-group">
              <label :for="`property_${index}`">{{ v.name }}</label>
              <input
                type="text"
                class="form-control"
                :id="`property_${index}`"
                :value="v.value"
                @input="changed($event, index)"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label :for="`property_${index}_formula`">{{ v.name }} 산출방법</label>
              <input type="text" class="form-control" :id="`property_${index}_formula`" :value="v.formula" @input="changed($event, index, 'formula')">
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-md-3">
      <template v-for="(v, index) in value.slice(value.length - 5)">
        <div class="form-group">
          <label :for="`property_${index + value.length - 5}`">{{ v.name }}</label>
          <input
              type="text"
              class="form-control"
              :id="`property_${index + value.length - 5}`"
              :value="v.value"
              @input="changed($event, index + value.length - 5)"
          >
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['value'],
    methods: {
      changed(ev, index, column = 'value') {
        const values = [...this.value];

        values[index] = {
          ...values[index],
          [column]: ev.target.value
        };

        this.$emit('input', values);
      }
    }
  }
</script>
