<template>
  <div class="alarm-card">
    <div class="card-heading">
      나의 세무알림
    </div>
    <div class="card-body">
      <div class="alarms">
        <template v-for="alarm in alarms">
          <div class="alarm">
            <div class="row">
              <div class="col-xs-3 col-sm-2">
                <div class="type text-center">
                  <div class="icon">
                    <i class="fal fa-file-alt" v-if="alarm.type === 'payslip'"></i>
                    <i class="fas fa-address-card" v-if="alarm.type === 'employee'"></i>
                    <i class="fas fa-user" v-if="alarm.type === 'registration'"></i>
                  </div>
                </div>
              </div>
              <div class="col-xs-9 col-sm-10">
                <div class="text">
                  {{ alarm.text }}
                </div>
                <div class="date">
                  {{ alarm.created_at }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('main', {
        alarms: state => state.alarms
      })
    }
  }
</script>
