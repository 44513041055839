<template>
  <div class="top-sub-menu">
    <div class="container">
      <ul>
        <template v-for="(menu, index) in menus">
          <li :key="`top-menu-${index}`">
            <router-link :to="{name: menu.link}">
              {{ menu.label }}
            </router-link>
          </li>
        </template>
        <li v-if="hasSlotData">
          <slot />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['menus'],
    computed: {
      hasSlotData() {
        return this.$slots.default;
      }
    }
  }
</script>
