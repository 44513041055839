import {DispatchContext, Job} from "@/core/types";

import * as phoneCertApi from '@/core/api/phone-cert';

export default class CheckPhoneCertNumberJob implements Job {
  private readonly phoneNumber: string;
  private readonly certNumber: string;

  constructor(phoneNumber: string, certNumber: string) {
    this.phoneNumber = phoneNumber;
    this.certNumber = certNumber;
  }

  async run(context: DispatchContext): Promise<any> {
    await phoneCertApi.check(this.phoneNumber, this.certNumber);
  }
}
