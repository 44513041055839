<template>
  <div class="container">
    <div class="form-group">
      <div class="row">
        <div class="col-md-8">
          <h1 class="page-title">
            사원 목록
          </h1>
        </div>
        <div class="col-md-4 text-right">
          <router-link
            style="margin-top: 17px;"
            class="btn btn-primary"
            :to="{name: 'employee.manage.create'}"
          >
            입사자 추가
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <new-employee />
      </div>
      <div class="col-md-6">
        <leaving-employee />
      </div>
    </div>
    <employee-list />
  </div>
</template>
<script>
  import EmployeeList from "@/components/employee/manage/EmployeeList";
  import LoadEmployeesJob from "@/core/jobs/employee/LoadEmployeesJob";
  import NewEmployee from "@/components/employee/manage/not-completed/NewEmployee";
  import LeavingEmployee from "@/components/employee/manage/not-completed/LeavingEmployee";
  import LoadNotCompletedEmployeesJob from "@/core/jobs/employee/LoadNotCompletedEmployeesJob";

  export default {
    components: {
      EmployeeList,
      NewEmployee,
      LeavingEmployee
    },
    mounted() {
      dispatch(new LoadEmployeesJob(0));
      dispatch(new LoadNotCompletedEmployeesJob());
    }
  }
</script>
