<template>
  <div>
    <router-view />
  </div>
</template>
<script>
  import TopSubMenu from "@/components/layouts/TopSubMenu.vue";

  export default {
    data() {
      return {
        menus: [
          {
            label: '공지사항',
            link: 'announcement.notice.index'
          },
          {
            label: '세무지식',
            link: 'announcement.knowhow.index'
          }
        ]
      };
    },
    components: {
      TopSubMenu
    }
  }
</script>
