import * as http from './http';

export function issue(phoneNumber: string) {
  return http.post('/api/v1/phone-cert/issue', {
    phone_number: phoneNumber
  });
}

export function check(phoneNumber: string, number: string) {
  return http.post('/api/v1/phone-cert/check', {
    phone_number: phoneNumber,
    number
  });
}
