// @ts-ignore
import jscookie from 'js-cookie';
import {DispatchContext, Job} from "@/core/types";
import postMessage from "@/core/post-message";

export default class LogoutJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    await context.authManager.logout();

    postMessage(JSON.stringify({
      type: 'logout'
    }));

    context.store.commit('auth/setUser', null);
  }
}
