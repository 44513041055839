<template>
  <div class="text-center" v-if="! isNaN(maxPage)">
    <ul class="pagination">
      <template v-for="cnt in maxPage">
        <li :class="{active: page === cnt}">
          <a href="javascript:;" @click="movePage(cnt)">{{ cnt }}</a>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
  export default {
    props: ['page', 'perPage', 'total'],
    computed: {
      maxPage() {
        return Math.ceil(this.total / this.perPage);
      }
    },
    methods: {
      movePage(page) {
        this.$emit('move-page', page);
      }
    }
  }
</script>
