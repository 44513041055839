import {DispatchContext, Job} from "@/core/types";
import * as userAlarmApi from '@/core/api/user-alarm';

export default class LoadUserAlarmJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    const response = await userAlarmApi.getAlarms();

    context.store.commit('main/setAlarms', response.data.data);
  }
}
