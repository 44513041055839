import store from '@/store';
import * as authManager from '@/core/auth/manager';
import {Job, DispatchContext} from "@/core/types";

// @ts-ignore
window.dispatch = async function(job: Job): Promise<any> {
  const context: DispatchContext = {
    store,
    authManager: {
      login: authManager.login,
      logout: authManager.logout
    }
  };

  store.commit('setIsLoading', true);

  let response;

  try {
    response = await job.run(context);
  } catch (e) {
    store.commit('setIsLoading', false);

    return Promise.reject(e);
  }

  store.commit('setIsLoading', false);

  return response;
};
