<template>
  <div class="auth">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>
<script>
  export default {

  }
</script>
