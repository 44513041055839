<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 직원별 급여입력
      </div>
      <div class="card-body">
        <div class="form-inline">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <select class="form-control" v-model="year">
                  <template v-for="index in (maxYear - 2010)">
                    <option :value="2010 + index">{{ 2010 + index }}년</option>
                  </template>
                </select>
              </div>
              &nbsp;
              <div class="form-group form-inline">
                <select class="form-control" v-model="month">
                  <template v-for="month in months">
                    <option :value="month">{{ month }}월</option>
                  </template>
                </select>
              </div>
              &nbsp;
              <div class="form-group text-right">
                <button type="button" class="btn btn-primary" @click="changeDate">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th width="80" class="hidden-xs"></th>
              <th width="120" class="hidden-xs">사원번호</th>
              <th width="120">이름</th>
              <th>지급액</th>
              <th width="80"></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(employee, index) in employees">
              <tr>
                <td align="center" class="hidden-xs">{{ total - (per_page * (page - 1)) - index }}</td>
                <td class="hidden-xs">{{ employee.code }}</td>
                <td>{{ employee.name }}</td>
                <template v-if="employee.payslip !== null">
                  <td align="right">{{ getWageValue(employee, 1) }}</td>
                </template>
                <template v-else>
                  <td align="right">0</td>
                </template>
                <td>
                  <button type="button"
                    class="btn btn-default"
                    @click="inputWage(employee)"
                    :disabled="! payslipEditable"
                  >
                    입력
                  </button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <pagination
          :page="page"
          :per-page="per_page"
          :total="total"
          @move-page="movePage"
        />
      </div>
    </div>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" v-if="selectedEmployee !== null">급여입력(사원명 : {{ selectedEmployee.name }})</h4>
          </div>
          <div class="modal-body">
            <div class="text-right">
              <button type="button" class="btn btn-primary" @click="getLatestPayslip">
                전월급여 불러오기
              </button>
            </div>
            <input-form
              v-model="properties"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveProperties">저장</button>
            <button type="button" class="btn btn-default" data-dismiss="modal">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import GetPayslipEmployeesJob from "../../../core/jobs/payslip/GetPayslipEmployeesJob";
  import {mapState, mapGetters} from 'vuex';
  import moment from "moment";
  import GetWagePropertiesJob from "@/core/jobs/payslip/GetWagePropertiesJob";
  import InputForm from "./InputForm";
  import InputWageJob from "@/core/jobs/payslip/InputWageJob";
  import LoadPayslipJob from "@/core/jobs/payslip/LoadPayslipJob";
  import SubmitPayslipJob from "@/core/jobs/payslip/SubmitPayslipJob";
  import LoadEmployeePropertiesJob from "@/core/jobs/payslip/LoadEmployeePropertiesJob";

  export default {
    data() {
      return {
        selectedEmployee: null,
        properties: [
        ],
        currentYear: '',
        currentMonth: '',
        year: '',
        month: '',
        months: [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
        ]
      };
    },
    computed: {
      maxYear() {
        return moment().format('YYYY');
      },
      ...mapState('payslip', {
        wages: state => state.wage_properties,
        employees: state => state.employees,
        total: state => state.total,
        per_page: state => state.per_page,
        page: state => state.page
      }),
      ...mapGetters('payslip', {
        payslipEditable: 'payslipEditable'
      })
    },
    methods: {
      getWageValue(employee, wageId) {
        const v = employee.payslip_values.filter(v => v.base_property_id === wageId);

        if (v.length === 0) {
          return 0;
        }

        return v[0].value.format();
      },
      saveProperties() {
        const year = this.currentYear;
        const month = this.currentMonth;

        dispatch(new InputWageJob({
          employee_id: this.selectedEmployee.id,
          year,
          month,
          properties: this.properties.map(value => {
            return {
              id: value.id,
              value: value.value,
              formula: value.formula
            };
          })
        })).then(() => {
          alert('입력 완료');
          dispatch(new GetPayslipEmployeesJob(this.page, this.currentYear, this.currentMonth, 'business'));
          $(this.$refs.modal).modal('hide');
        }).catch(() => {
          alert('모든 항목의 입력값을 숫자로 입력해주세요.');
        })
      },
      inputWage(employee) {
        this.selectedEmployee = employee;

        const properties = [
          {
            id: 1,
            name: '지급액',
            value: 0
          }
        ];

        this.properties = [];

        properties.forEach(property => {
          let value = 0;
          let formula = '';
          const values = employee.payslip_values.filter(v => v.base_property_id === property.id);

          if (values.length > 0) {
            value = values[0].value;
            formula = values[0].formula;
          }

          this.properties.push({
            id: property.id,
            name: property.name,
            value,
            formula
          });
        });

        $(this.$refs.modal).modal('show');
      },
      changeDate() {
        this.currentYear = this.year;
        this.currentMonth = this.month;

        this.$router.replace({
          name: 'payslip.business.index',
          query: {
            year: this.year,
            month: this.month
          }
        }).catch(err => {
        });

        dispatch(new LoadPayslipJob(this.year, this.month));
        dispatch(new GetPayslipEmployeesJob(0, this.currentYear, this.currentMonth, 'business'));
      },
      movePage(page) {
        dispatch(new GetPayslipEmployeesJob(page, this.currentYear, this.currentMonth, 'business'));
      },
      getLatestPayslip() {
        const d = moment(`${this.currentYear}-${this.currentMonth}-01`, 'YYYY-MM-DD');
        d.subtract(1, 'months');

        dispatch(new LoadEmployeePropertiesJob(d.year(), `0${d.month() + 1}`.substr(-2), this.selectedEmployee.id)).then(data => {
          this.properties.forEach(property => {
            const oldProperty = data.payslip_values.filter(v => v.base_property_id === property.id);

            if (oldProperty.length > 0) {
              property.value = oldProperty[0].value;
              property.comment = oldProperty[0].comment;
              property.formula = oldProperty[0].formula;
            }
          });
        });
      }
    },
    mounted() {
      if (this.$route.query.year === undefined || this.$route.query.month === undefined) {
        this.year = this.currentYear = moment().format('YYYY');
        this.month = this.currentMonth = moment().format('MM');
      } else {
        this.year = this.currentYear = this.$route.query.year;
        this.month = this.currentMonth = this.$route.query.month;
      }

      dispatch(new LoadPayslipJob(this.year, this.month));
      dispatch(new GetPayslipEmployeesJob(0, this.year, this.month, 'business'));
      dispatch(new GetWagePropertiesJob());
    },
    components: {
      InputForm
    }
  }
</script>
