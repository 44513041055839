import {DispatchContext, Job} from "@/core/types";
import {submit} from "@/core/api/payslip";

export default class SubmitPayslipJob implements Job {
  private readonly year: string;
  private readonly month: string;

  constructor(year: string, month: string) {
    this.year = year;
    this.month = month;
  }

  async run(context: DispatchContext): Promise<any> {
    await submit({
      year: this.year,
      month: this.month
    });
  }
}
