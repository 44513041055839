// @ts-ignore
import jscookie from 'js-cookie';
import {DispatchContext, Job} from "@/core/types";

import {RegisterData, register, user} from "@/core/api/auth";

export default class RegisterJob implements Job {
  private readonly data: RegisterData;

  constructor(data: RegisterData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    await register(this.data);
    await context.authManager.login(this.data.userid, this.data.password);
    const response = await user();

    context.store.commit('auth/setUser', response.data.data);
  }
}
