<template>
  <div>
    <user-info
      :employee="employee"
    />
    <input-form
      v-model="values"
    />
    <div class="text-right">
      <button type="button" class="btn btn-primary" @click="save">
        확인
      </button>
      &nbsp;
      <button type="button" class="btn btn-default" @click="$router.back()">
        취소
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import UserInfo from "./UserInfo";
  import InputForm from "./InputForm";
  import InputWageJob from "@/core/jobs/payslip/InputWageJob";

  export default {
    data() {
      return {
        values: []
      };
    },
    computed: {
      ...mapState('payslip', {
        employee: state => state.selectedEmployee
      })
    },
    methods: {
      save() {
        const year = parseInt(this.$route.query.year);
        const month = parseInt(this.$route.query.month);

        dispatch(new InputWageJob({
          employee_id: this.employee.id,
          year,
          month,
          properties: this.values.map(value => {
            return {
              id: value.id,
              value: value.value
            };
          })
        })).then(() => {
          alert('입력 완료');
          this.$router.back();
        });
      }
    },
    mounted() {
      const properties = [
        {
          id: 1,
          name: '기본급',
          value: 0
        }
      ];

      properties.forEach(property => {
        let value = 0;

        if (this.employee.payslip !== null) {
          const values = this.employee.payslip.values.filter(v => v.base_property_id === property.id);

          if (values.length > 0) {
            value = values[0].value;
          }
        }

        this.values.push({
          id: property.id,
          name: property.name,
          value
        });
      });
    },
    components: {
      UserInfo,
      InputForm
    }
  }
</script>
