import {PaginateData} from "@/core/types";
import {Employee} from "@/core/types/model";

export default {
  namespaced: true,
  state: {
    search: {
      search_type: 'code',
      search_value: '',
      employee_type: [],
      work_type: [],
    },
    selected_employee: null,
    not_completed: [],
    employees: [],
    page: 0,
    per_page: 0,
    total: 0
  },
  getters: {
    notCompletedNewEmployees(state: any) {
      return state.not_completed.filter((employee: Employee) => !employee.in_completed);
    },
    notCompletedLeavingEmployees(state: any) {
      return state.not_completed.filter((employee: Employee) => !employee.out_completed && employee.out_date !== null);
    }
  },
  mutations: {
    setSearchValue(state: any, { search_type, search_value, employee_type, work_type }: any) {
      state.search.search_type = search_type;
      state.search.search_value = search_value;
      state.search.employee_type = employee_type;
      state.search.work_type = work_type;
    },
    setEmployees(state: any, data: PaginateData) {
      state.employees = [
        ...data.data
      ];
      state.page = data.page;
      state.per_page = data.per_page;
      state.total = data.total;
    },
    setNotCompleted(state: any, data: Array<Employee>) {
      state.not_completed = [
        ...data
      ];
    },
    selectEmployee(state: any, data: Employee) {
      state.selected_employee = {
        ...data
      };
    }
  }
}
