<template>
  <div class="container" v-if="loaded">
    <h1 class="page-title">사원 정보 수정</h1>
    <edit-form />
  </div>
</template>
<script>
  import EditForm from "@/components/employee/manage/EditForm.vue";
  import SelectEmployeeJob from "@/core/jobs/employee/SelectEmployeeJob";

  export default {
    data() {
      return {
        loaded: false
      };
    },
    components: {
      EditForm
    },
    mounted() {
      dispatch(new SelectEmployeeJob(parseInt(this.$route.params.id))).then(() => {
        this.loaded = true;
      });
    }
  }
</script>
