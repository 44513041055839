<template>
  <div>
    <div class="heading">
      <div class="logo">
        <img src="../../assets/logo/logo.png" alt="로고" width="200">
      </div>
      <h4>비밀번호 변경</h4>
    </div>
    <div class="body">
      <div class="form-group">
        <label for="email">이메일</label>
        <v-input
          type="email"
          id="email"
          placeholder="이메일"
          v-model="userInput.email"
          :errors="errors.email"
        />
      </div>
      <div class="form-group">
        <label for="password">비밀번호</label>
        <v-input
          type="password"
          id="password"
          placeholder="비밀번호"
          v-model="userInput.password"
          :errors="errors.password"
        />
      </div>
      <div class="form-group">
        <label for="password_confirmation">비밀번호 확인</label>
        <v-input
          type="password"
          id="password_confirmation"
          placeholder="비밀번호 확인"
          v-model="userInput.password_confirmation"
          :errors="errors.password_confirmation"
        />
      </div>
      <div class="form-group">
        <label for="token">인증번호</label>
        <v-input
          type="text"
          id="token"
          placeholder="인증번호"
          v-model="userInput.token"
          :errors="errors.token"
        />
      </div>
    </div>
    <div class="footer">
      <button type="button" class="btn btn-primary btn-block" @click="change">
        확인
      </button>
    </div>
  </div>
</template>
<script>
  import ResetPasswordJob from "@/core/jobs/auth/ResetPasswordJob";

  export default {
    data() {
      return {
        userInput: {
          email: '',
          password: '',
          password_confirmation: '',
          token: ''
        },
        errors: {}
      };
    },
    methods: {
      change() {
        dispatch(new ResetPasswordJob({
          email: this.userInput.email,
          password: this.userInput.password,
          password_confirmation: this.userInput.password_confirmation,
          token: this.userInput.token
        })).then(() => {
          alert('수정 완료');
          this.$router.push({name: 'login'});
        }).catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          }
        });
      }
    }
  }
</script>
