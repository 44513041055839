<template>
  <div class="container">
    <h1 class="page-title">서식모음</h1>
    <template-list />
  </div>
</template>
<script>
  import TemplateList from "../../../components/announcement/template/TemplateList";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";

  export default {
    components: {
      TemplateList
    },
    mounted() {
      dispatch(new LoadAnnouncementJob(0, 'template'));
    }
  }
</script>
