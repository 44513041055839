import * as http from './http';

export interface RegisterData {
  userid: string,
  name: string,
  password: string,
  password_confirmation: string,
  email: string,
  company_number: string,
  pay_day_type: string,
  pay_day: number,
  phone_number: string,
  number: string
}

export interface UpdateUserData {
  name: string,
  password: string,
  password_confirmation: string,
  email: string,
  company_number: string,
  pay_day_type: string,
  pay_day: number
}

export interface UpdatePhoneNumberData {
  phone_number: string,
  number: string
}

export interface ResetPasswordData {
  email: string,
  password: string,
  password_confirmation: string,
  token: string
}

export function login(userid: string, password: string) {
  return http.post('/api/v1/auth/login', {
    userid,
    password
  });
}

export function user() {
  return http.get('/api/v1/auth/user');
}

export function register(data: RegisterData) {
  return http.post('/api/v1/auth/register', data);
}

export function updateUser(data: UpdateUserData) {
  return http.put('/api/v1/auth/update', data);
}

export function updatePhoneNumber(data: UpdatePhoneNumberData) {
  return http.put('/api/v1/auth/update', data);
}

export function sendResetLink(email: string) {
  return http.post('/api/v1/auth/reset', {
    email
  });
}

export function resetPassword(data: ResetPasswordData) {
  return http.post('/api/v1/auth/reset-password', data);
}
