import {DispatchContext, Job} from "@/core/types";
import {confirmPayslip} from "@/core/api/payslip";

export default class ConfirmPayslipJob implements Job {
  private readonly type: string;
  private readonly year: string;
  private readonly month: string;
  private readonly reason: string;

  constructor(type: string, year: string, month: string, reason: string) {
    this.type = type;
    this.year = year;
    this.month = month;
    this.reason = reason;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await confirmPayslip({
      type: this.type,
      year: this.year,
      month: this.month,
      reason: this.reason
    });

  }
}
