import {DispatchContext, Job} from "@/core/types";
import {UpdateEmployeeData, updateEmployee} from "@/core/api/employee";
import LoadEmployeesJob from "@/core/jobs/employee/LoadEmployeesJob";

export default class UpdateEmployeeJob implements Job {
  private readonly id: number;
  private readonly data: UpdateEmployeeData;

  constructor(id: number, data: UpdateEmployeeData) {
    this.id = id;
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await updateEmployee(this.id, this.data);

    // @ts-ignore
    return dispatch(new LoadEmployeesJob(context.store.state.employee.page));
  }
}
