import Page from '@/views/announcement/Page.vue';

import NoticeIndexView from '@/views/announcement/notice/Index.vue';
import NoticeShowView from '@/views/announcement/notice/Show.vue';

import KnowhowIndexView from '@/views/announcement/knowhow/Index.vue';
import KnowhowShowView from '@/views/announcement/knowhow/Show.vue';

import TemplateList from '@/views/announcement/template/Index.vue';

export default [
  {
    path: '/announcement',
    component: Page,
    children: [
      {
        path: 'template',
        component: TemplateList,
        name: 'announcement.template.index'
      },
      {
        path: 'notice',
        component: NoticeIndexView,
        name: 'announcement.notice.index'
      },
      {
        path: 'notice/:id',
        component: NoticeShowView,
        name: 'announcement.notice.show'
      },
      {
        path: 'knowhow',
        component: KnowhowIndexView,
        name: 'announcement.knowhow.index'
      },
      {
        path: 'knowhow/:id',
        component: KnowhowShowView,
        name: 'announcement.knowhow.show'
      }
    ]
  }
];
