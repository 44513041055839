import {DispatchContext, Job} from "@/core/types";
import {getPayslip} from "@/core/api/payslip";

export default class LoadPayslipJob implements Job {
  private year: string;
  private month: string;

  constructor(year: string, month: string) {
    this.year = year;
    this.month = month;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getPayslip({
      year: this.year,
      month: this.month
    });

    context.store.commit('payslip/setPayslip', response.data.data);
  }
}
