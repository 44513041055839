import {DispatchContext, Job} from "@/core/types";
import {user} from "@/core/api/auth";

export default class SetUserFromTokenJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    const response = await user();

    context.store.commit('auth/setUser', response.data.data);
  }
}
