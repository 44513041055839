import {DispatchContext, Job} from "@/core/types";
import {user} from "@/core/api/auth";

export default class GetUserFromSessionTokenJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    const userResponse = await user();

    context.store.commit('auth/setUser', userResponse.data.data);
  }
}
