import {DispatchContext, Job} from "@/core/types";
import {UpdatePhoneNumberData, updatePhoneNumber} from "@/core/api/auth";

export default class UpdateUserPhoneNumberJob implements Job {
  private readonly data: UpdatePhoneNumberData;

  constructor(data: UpdatePhoneNumberData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await updatePhoneNumber(this.data);

    context.store.commit('auth/setUser', response.data.data);
  }
}
