<template>
  <div class="invoice">
    <invoice-header
      :year="year"
      :month="month"
      @change="changeYearMonth"
    />
    <br />
    <template v-if="payslip !== null">
      <confirm
        @save="save"
      />
      <div class="text-center">
        <div>
          <div class="form-group">
            <button type="button" class="btn btn-default" @click="downloadPayslip">
              <i class="fa fa-file-download"></i> 급여명세서 다운로드
            </button>
            &nbsp;
            <button type="button" class="btn btn-default" @click="downloadPayroll('일반근로자')">
              <i class="fa fa-file-download"></i> 급여대장 다운로드
            </button>
            &nbsp;
            <button type="button" class="btn btn-default" @click="downloadPayroll('사업소득자')">
              <i class="fa fa-file-download"></i> 사업소득자 급여대장 다운로드
            </button>
          </div>
          <button type="button"
                  class="btn btn-default"
                  v-if="!isApp"
                  onclick="window.open('/print/payslip', '_blank')"
          >
            <i class="fa fa-print"></i> 급여명세서 출력
          </button>
          &nbsp;
          <button type="button"
                  class="btn btn-default"
                  v-if="!isApp"
                  onclick="window.open('/print/payroll', '_blank')"
          >
            <i class="fa fa-print"></i> 급여대장 출력
          </button>
          &nbsp;
          <button type="button"
                  class="btn btn-default"
                  v-if="!isApp"
                  onclick="window.open('/print/payroll-business', '_blank')"
          >
            <i class="fa fa-print"></i> 사업소득자 급여대장 출력
          </button>
        </div>
        <br />
      </div>
      <invoice-table
        title="일반근로자 급여지급 현황"
        @send-email="sendEmail"
        :employees="normalEmployees"
        :payslip="payslip"
      />
      <invoice-table
        title="사업소득자 급여지급 현황"
        @send-email="sendEmail"
        :payslip="payslip"
        :employees="businessEmployees"
      />
      <invoice-table
        title="일용근로자 급여지급 현황"
        @send-email="sendEmail"
        :payslip="payslip"
        :employees="dailyEmployees"
      />
      <div>
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-file-download"></i> 일반 근로자 신고내역 확인
          </div>
          <div class="card-body">
            <template v-if="payslip.employee_file1 !== null">
              <button type="button" class="btn btn-primary" @click="downloadFile(payslip.employee_file1, '원천징수 이행상황 신고서')" v-if="isApp">
                원천징수 이행상황 신고서
              </button>
              <a :href="payslip.employee_file1" target="_blank" class="btn btn-primary" v-else>
                원천징수 이행상황 신고서
              </a>
            </template>
            &nbsp;
            <template v-if="payslip.employee_file2 !== null">
              <button type="button" class="btn btn-primary" @click="downloadFile(payslip.employee_file2, '원천징수 납부서')" v-if="isApp">
                원천징수 납부서
              </button>
              <a :href="payslip.employee_file2" target="_blank" class="btn btn-primary" v-else>
                원천징수 납부서
              </a>
            </template>
            &nbsp;
            <template v-if="payslip.employee_file3 !== null">
              <button type="button" class="btn btn-primary" v-if="isApp" @click="downloadFile(payslip.employee_file3, '원천징수 지방소득세 납부서')">
                원천징수 지방소득세 납부서
              </button>
              <a :href="payslip.employee_file3" target="_blank" class="btn btn-primary" v-else>
                원천징수 지방소득세 납부서
              </a>
            </template>
          </div>
        </div>

        <div class="card">
          <div class="card-heading">
            <i class="fa fa-file-download"></i> 사업소득자 신고내역 확인
          </div>
          <div class="card-body">
            <template v-if="payslip.business_file1 !== null">
              <button type="button" class="btn btn-primary" v-if="isApp" @click="downloadFile(payslip.business_file1, '사업소득원천징수영수증')">
                사업소득원천징수영수증
              </button>
              <a :href="payslip.business_file1" target="_blank" class="btn btn-primary" v-else>
                사업소득원천징수영수증
              </a>
            </template>
          </div>
        </div>

        <div class="card">
          <div class="card-heading">
            <i class="fa fa-file-download"></i> 일용근로자 신고내역 확인
          </div>
          <div class="card-body">
            <template v-if="payslip.daily_file1 !== null">
              <button type="button" class="btn btn-primary" v-if="isApp" @click="downloadFile(payslip.daily_file1, '근로내용확인신고서')">
                근로내용확인신고서
              </button>
              <a :href="payslip.daily_file1" target="_blank" class="btn btn-primary" v-else>
                근로내용확인신고서
              </a>
            </template>
            &nbsp;
            <template v-if="payslip.daily_file2 !== null">
              <button type="button" class="btn btn-primary" v-if="isApp" @click="downloadFile(payslip.daily_file2, '일용근로자 지급명세서')">
                일용근로자 지급명세서
              </button>
              <a :href="payslip.daily_file2" target="_blank" class="btn btn-primary" v-else>
                일용근로자 지급명세서
              </a>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="input-yet">
        <i class="fa fa-folder-open"></i>
        <h4>입력된 급여가 없습니다.</h4>
      </div>
    </template>
  </div>
</template>
<script>
  import InvoiceHeader from './Header';
  import moment from 'moment';
  import {mapState, mapGetters} from 'vuex';
  import GetPayslipJob from "@/core/jobs/payslip/GetPayslipJob";
  import InvoiceTable from "@/components/invoice/InvoiceTable";
  import DownloadPayslipJob from "@/core/jobs/payslip/DownloadPayslipJob";
  import DownloadPayrollJob from "@/core/jobs/payslip/DownloadPayrollJob";
  import DownloadAttachmentJob from "@/core/jobs/payslip/DownloadAttachmentJob";
  import Confirm from "@/components/invoice/Confirm";
  import ConfirmPayslipJob from "@/core/jobs/invoice/ConfirmPayslipJob";
  import SendEmailJob from "@/core/jobs/payslip/SendEmailJob";
  import postMessage from "@/core/post-message";
  import SendEmailsJob from "@/core/jobs/payslip/SendEmailsJob";

  export default {
    data() {
      return {
      };
    },
    computed: {
      ...mapState('invoice', {
        payslip: state => state.payslip,
        year: state => state.year,
        month: state => state.month
      }),
      ...mapState('app', {
        isApp: state => state.isApp
      }),
      ...mapGetters('invoice', {
        normalEmployees: 'normalEmployees',
        businessEmployees: 'businessEmployees',
        dailyEmployees: 'dailyEmployees',
        declarationAttachment: 'declarationAttachment',
        withholdingTaxAttachment: 'withholdingTaxAttachment',
        specialTaxAttachment: 'specialTaxAttachment'
      })
    },
    methods: {
      downloadFile(url, name) {
        const extension = url.split('.').pop();

        postMessage(JSON.stringify({
          type: 'download',
          method: 'get',
          filename: `${name}.${extension}`,
          url
        }));
      },
      sendEmail(idList) {
        if (!confirm('이메일을 전송하시겠습니까?')) {
          return;
        }

        const arr = [];

        arr.push(dispatch(new SendEmailsJob(idList, this.year, this.month)));

        Promise.all(arr).then(() => {
          alert('이메일을 전송하였습니다.');
        });
      },
      save({ type, reason }) {
        if (! confirm('급여 명세서를 확정하시겠습니까?')) {
          return;
        }

        dispatch(new ConfirmPayslipJob(
          type,
          this.year,
          this.month,
          reason
        )).then(() => {
          dispatch(new GetPayslipJob(this.year, this.month));
        });
      },
      download(attachment) {
        if (attachment === null) {
          alert('아직 파일이 업로드되지 않았습니다.');
          return;
        }

        dispatch(new DownloadAttachmentJob(attachment));
      },
      changeYearMonth({ year, month }) {
        this.$router.push({
          name: 'invoice.index',
          query: {
            year,
            month
          }
        });

        dispatch(new GetPayslipJob(year, month));
      },
      downloadPayslip() {
        dispatch(new DownloadPayslipJob(this.year, this.month));
      },
      downloadPayroll(type) {
        dispatch(new DownloadPayrollJob(this.year, this.month, type));
      }
    },
    created() {
      let year, month;

      if (this.$route.query.year !== undefined && this.$route.query.month !== undefined) {
        year = this.$route.query.year;
        month = this.$route.query.month;
      } else {
        year = moment().format('YYYY');
        month = moment().format('MM');
      }

      dispatch(new GetPayslipJob(year, month));
    },
    components: {
      InvoiceHeader,
      InvoiceTable,
      Confirm
    }
  }
</script>
