<template>
  <div class="auth">
    <div class="container">
      <div class="auth-form">
        <div class="heading">
          <div class="logo">
            <img src="../../assets/logo/logo.png" alt="로고" width="200">
          </div>
          <h4>호연 급여관리 시스템</h4>
        </div>
        <div class="body text-center not-certified">
          <h2>승인 대기중</h2>
          <p>
            관리자의 승인이 있어야<br />
            서비스를 이용할 수 있습니다.<br /><br />
            <strong>승인 완료시 SMS로 안내 드리겠습니다.</strong>
          </p>
        </div>
        <div class="footer"></div>
        <div class="text-right">
          <button type="button" class="btn btn-primary btn-block" @click="logout">
            로그아웃
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LogoutJob from "@/core/jobs/auth/LogoutJob";
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('app', {
        isApp: state => state.isApp
      })
    },
    methods: {
      logout() {
        dispatch(new LogoutJob()).then(() => {
          if (! this.isApp) {
            this.$router.push('/auth/login');
          }
        });
      }
    }
  }
</script>
