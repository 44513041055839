import {DispatchContext, Job} from "@/core/types";

import {createEmployee, CreateEmployeeData} from "@/core/api/employee";

export default class CreateEmployeeJob implements Job {
  private readonly data: CreateEmployeeData;

  constructor(data: CreateEmployeeData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await createEmployee(this.data);
  }
}
