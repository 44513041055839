import axios, {AxiosRequestConfig} from 'axios';
import router from '@/router';
import {getToken, logout} from "@/core/auth/manager";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    logout().then(() => {
      router.push('/auth/login')
    });
  }

  return Promise.reject(error);
});

instance.interceptors.request.use(config => {
  if (! (config.data instanceof FormData)) {
    const data = config.data;
    let formData = new FormData();

    for (let key in data) {
      if (data[key] === true) {
        formData.append(key, '1');
      } else if (data[key] === false) {
        formData.append(key, '0');
      } else if (Array.isArray(data[key])) {
        data[key].forEach((item: any, index: number) => {
          if (item instanceof Object) {
            for (let key2 in item) {
              formData.append(`${key}[${index}][${key2}]`, item[key2]);
          }
          } else {
            formData.append(`${key}[${index}]`, item);
          }
        })
      } else {
        formData.append(key, data[key]);
      }
    }

    config.data = formData;
  }

  if (config.method === 'put') {
    config.method = 'post';
    config.data.append('_method', 'put');
  }

  if (config.method === 'delete') {
    config.method = 'post';
    config.data.append('_method', 'delete');
  }

  return config;
});

async function setAuthorizationHeader(config: AxiosRequestConfig) {
  const token = await getToken();

  if (token !== null) {
    if (config.headers === undefined) {
      config.headers = {
        'Authorization': `Bearer ${token}`
      };
    } else {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }
}

export async function get(url: string, config: AxiosRequestConfig = {}) {
  await setAuthorizationHeader(config);

  return instance.get(url, config);
}

export async function post(url: string, data?: any, config: AxiosRequestConfig = {}) {
  await setAuthorizationHeader(config);

  return instance.post(url, data, config);
}

export async function put(url: string, data?: any, config: AxiosRequestConfig = {}) {
  await setAuthorizationHeader(config);

  if (data === undefined) {
    data = new FormData();
  }

  if (data instanceof FormData) {
    data.append('_method', 'put');
  } else {
    data['_method'] = 'put';
  }

  return instance.post(url, data, config);
}

export async function destroy(url: string, config: AxiosRequestConfig = {}) {
  await setAuthorizationHeader(config);

  return instance.post(url, {
    '_method': 'delete',
  }, config);
}
