// @ts-ignore
import jscookie from 'js-cookie';
import Auth from '@/Auth.vue';

import LoginView from '@/views/auth/Login.vue';
import RegisterView from '@/views/auth/Register.vue';
import ResetPasswordView from '@/views/auth/ResetPassword.vue';
import ResetPasswordConfirmView from '@/views/auth/ResetPasswordConfirm.vue';

export default [
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        component: LoginView,
        name: 'login'
      },
      {
        path: 'register',
        component: RegisterView,
        name: 'register'
      },
      {
        path: 'reset',
        component: ResetPasswordView,
        name: 'reset'
      },
      {
        path: 'reset-confirm',
        component: ResetPasswordConfirmView,
        name: 'reset-confirm'
      }
    ],
    beforeEnter(to: any, from: any, next: any) {
      const token = jscookie.get('user-token');

      // @ts-ignore
      if (token !== undefined) {
        next('/dashboard');
      } else {
        next();
      }
    }
  }
];
