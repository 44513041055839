<template>
  <div class="print-payslip">
    <template v-for="(employee, index) in payslip.employees">
      <payslip
        :year="year"
        :month="month"
        :employee="employee"
        :user="user"
      />
      <div class="page-break" v-if="index < payslip.employees.length - 1"></div>
    </template>
  </div>
</template>
<script>
  import Payslip from "@/views/print/payslip/Payslip";
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('auth', {
        user: state => state.user
      }),
      ...mapState('invoice', {
        payslip: state => state.payslip,
        year: state => state.year,
        month: state => state.month
      })
    },
    mounted() {
      window.print();
    },
    components: {
      Payslip
    }
  }
</script>
