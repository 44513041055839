<template>
  <div>
    <h2>{{ year }}년 {{ month }}월 급여명세서</h2>
    <table>
      <colgroup>
        <col width="16%" />
        <col width="16%" />
        <col width="16%" />
        <col width="16%" />
        <col width="16%" />
        <col width="16%" />
      </colgroup>
      <tr>
        <td>회사명</td>
        <td colspan="5">{{ user.name }}</td>
      </tr>
      <tr>
        <td>사원번호</td>
        <td>{{ employee.code }}</td>
        <td>사원명</td>
        <td>{{ employee.name }}</td>
        <td>입사일</td>
        <td>{{ employee.in_date }}</td>
      </tr>
      <tr>
        <td>직급</td>
        <td>{{ employee.position }}</td>
        <td>부서</td>
        <td>{{ employee.department }}</td>
        <td>퇴사일</td>
        <td>{{ employee.out_date }}</td>
      </tr>
      <tr>
        <th>급여항목</th>
        <th colspan="2">지급액</th>
        <th>공제항목</th>
        <th colspan="2">지급액</th>
      </tr>
      <template v-for="(num, index) in maxRowCount">
        <tr>
          <td align="center">
            {{ wages.length > index ? wages[index].name : '' }}
          </td>
          <td colspan="2" align="right">
            {{ wages.length > index ? wages[index].value.format() : '' }}
          </td>
          <td align="center">
            {{ excludes.length > index ? excludes[index].name : '' }}
          </td>
          <td colspan="2" align="right">
            {{ excludes.length > index ? excludes[index].value.format() : '' }}
          </td>
        </tr>
      </template>
      <tr>
        <td>&nbsp;</td>
        <td colspan="2">&nbsp;</td>
        <th>합계</th>
        <td colspan="2" align="right">
          {{ totalExcludes.format() }}
        </td>
      </tr>
      <tr>
        <th>합계</th>
        <td colspan="2" align="right">
          {{ totalWages.format() }}
        </td>
        <th>차인지급액</th>
        <td colspan="2" align="right">
          {{ (totalWages - totalExcludes).format() }}
        </td>
      </tr>
    </table>
    <template v-if="['일반근로자', '사업소득자'].indexOf(employee.employee_type) >= 0">
      <template v-if="employee.employee_type === '일반근로자'">
        <br />
        <table>
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <tr>
            <th>근로일수</th>
            <th>총근로시간수</th>
            <th>연장근로시간수</th>
            <th>야간근로시간수</th>
            <th>휴일근로시간수</th>
          </tr>
          <tr>
            <td align="right">{{ workDays.format() }}</td>
            <td align="right">{{ workHours.format() }}</td>
            <td align="right">{{ overWorkHours.format() }}</td>
            <td align="right">{{ nightWorkHours.format() }}</td>
            <td align="right">{{ holidayWorkHours.format() }}</td>
          </tr>
        </table>
      </template>
      <br />
      <table>
        <colgroup>
          <col width="30%" />
          <col width="*" />
          <col width="30%" />
        </colgroup>
        <tr>
          <th colspan="3">계산방법</th>
        </tr>
        <tr>
          <th>구분</th>
          <th>산출식 또는 산출방법</th>
          <th>지급액</th>
        </tr>
        <template v-for="property in wageCalculateInfo">
          <tr>
            <td>{{ property.name }}</td>
            <td align="center">{{ property.formula }}</td>
            <td>{{ property.value.format() }}</td>
          </tr>
        </template>
        <template v-for="(property) in excludeCalculateInfo">
          <tr>
            <td align="center">
              {{ property.name }}
            </td>
            <td align="center">
              <template v-if="property.name === '소득세'">
                간이세액표 적용
              </template>
              <template v-if="property.name === '지방소득세'">
                소득세 X 10%
              </template>
              <template v-if="property.name === '국민연금'">
                {{ user.annuity_tax_rate }}
              </template>
              <template v-if="property.name === '고용보험'">
                {{ user.hire_insurance_rate }}
              </template>
              <template v-if="property.name === '건강보험'">
                {{ user.health_insurance_rate }}
              </template>
              <template v-if="property.name === '장기요양보험'">
                {{ user.treatment_insurance_rate }}
              </template>
            </td>
            <td colspan="2" align="right">
              {{ property.value.format() }}
            </td>
          </tr>
        </template>
      </table>
    </template>
    <template v-if="employee.employee_type === '일용근로자'">
      <br />
      <span style="font-size: 20px; margin-bottom: 10px;">근로일</span>
      <table class="working-calendar">
        <template v-for="row in 6">
          <tr>
            <template v-for="col in 6">
              <td
                  :class="{active: workingDays.indexOf(`${((row - 1) * 6) + col}`) >= 0}"
                  width="16%"
                  v-if="((row - 1) * 6) + col < 32"
              >
                {{ ((row - 1) * 6) + col }}
              </td>
            </template>
          </tr>
        </template>
      </table>
    </template>
  </div>
</template>
<script>
  import TemplateList from "@/components/announcement/template/TemplateList";
  export default {
    components: {TemplateList},
    props: ['year', 'month', 'employee', 'user'],
    computed: {
      workDays() {
        const arr = this.etcList.filter(v => v.base_property_id === 1003);

        if (arr.length <= 0) {
          return 0;
        }

        return arr[0].value;
      },
      workHours() {
        const arr = this.etcList.filter(v => v.base_property_id === 1004);

        if (arr.length <= 0) {
          return 0;
        }

        return arr[0].value;
      },
      overWorkHours() {
        const arr = this.etcList.filter(v => v.base_property_id === 1005);

        if (arr.length <= 0) {
          return 0;
        }

        return arr[0].value;
      },
      nightWorkHours() {
        const arr = this.etcList.filter(v => v.base_property_id === 1006);

        if (arr.length <= 0) {
          return 0;
        }

        return arr[0].value;
      },
      holidayWorkHours() {
        const arr = this.etcList.filter(v => v.base_property_id === 1007);

        if (arr.length <= 0) {
          return 0;
        }

        return arr[0].value;
      },
      etcList() {
        return this.employee.payslip_values.filter(v => v.type === 'etc').sort((a, b) => a.id - b.id);
      },
      wages() {
        return this.employee.payslip_values.filter(v => v.type === 'wage');
      },
      excludes() {
        return this.employee.payslip_values.filter(v => v.type === 'exclude');
      },
      totalWages() {
        return this.wages.filter(wage => wage.base_property_id !== 1000 && wage.base_property_id !== 1001).reduce((a, b) => a + b.value, 0);
      },
      totalExcludes() {
        return this.excludes.reduce((a, b) => a + b.value, 0);
      },
      calculateInfo() {
        return [
            ...this.wages.filter(wage => wage.value > 0),
            ...this.excludes.filter(exclude => ['소득세', '지방소득세', '국민연금', '고용보험', '건강보험', '장기요양보험'].indexOf(exclude.name) >= 0 && exclude.value > 0)
        ];
      },
      wageCalculateInfo() {
        if (this.employee.employee_type === '사업소득자') {
          return this.wages.filter(wage => wage.value > 0);
        } else if (this.employee.employee_type === '일용근로자') {
          return [];
        } else {
          return this.wages.filter(wage => wage.value > 0);
        }
      },
      excludeCalculateInfo() {
        if (this.employee.employee_type === '사업소득자') {
          return this.excludes.filter(exclude => ['소득세', '지방소득세'].indexOf(exclude.name) >= 0 && exclude.value > 0).map(exclude => ({
            ...exclude,
            formula: exclude.name === '소득세' ? '과세대상금액 X 3%' : '소득세 X 10%',
          }));
        } else if (this.employee.employee_type === '일용근로자') {
          return [];
        } else {
          return this.excludes.filter(exclude => ['소득세', '지방소득세', '국민연금', '고용보험', '건강보험', '장기요양보험'].indexOf(exclude.name) >= 0 && exclude.value > 0);
        }
      },
      workingDays() {
        if (this.employee.employee_type === '일용근로자') {
          const workingDays = this.wages.filter(wage => ['근로일수'].indexOf(wage.name) >= 0 && wage.value > 0);

          if (workingDays.length > 0) {
            return workingDays[0].comment.split(',');
          }

          return [];
        }

        return [];
      },
      maxRowCount() {
        return Math.max(this.wages.length, this.excludes.length);
      }
    }
  }
</script>
