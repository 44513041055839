<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user"></i> 사원 정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="code">사원번호</label>
              <input type="text" id="code" :value="employee.code" readonly class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">이름</label>
              <input type="text" id="name" :value="employee.name" readonly class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="birthday">생년월일</label>
              <input type="text" id="birthday" :value="employee.birthday" readonly class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="working_hour">주소정근로시간</label>
              <input type="text" id="working_hour" :value="employee.working_hour" readonly class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="position">직급</label>
              <input type="text" id="position" :value="employee.position" readonly class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="department">부서</label>
              <input type="text" id="department" :value="employee.department" readonly class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="employee_type">근무타입</label>
              <input type="text" id="employee_type" :value="employee.employee_type" readonly class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="pay_type">급여타입</label>
              <input type="text" id="pay_type" :value="employee.pay_type" readonly class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="income">총월소득액(비과세제외)</label>
              <input type="text" id="income" :value="employee.income" readonly class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-hands-helping"></i> 피부양자
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>성명</th>
            <th>관계</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="dependent in employee.dependents">
            <tr>
              <td>{{ dependent.name }}</td>
              <td>{{ dependent.relationship }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-address-card"></i> 퇴사 정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" v-if="employee.employee_type === '일반근로자'">
            <div class="form-group">
              <label for="out_reason">퇴사사유</label>
              <select class="form-control" id="out_reason" v-model="out_reason">
                <option value="">선택해주세요</option>
                <option value="11(개인사정으로 인한 자진퇴사)">11(개인사정으로 인한 자진퇴사)</option>
                <option value="12(사업장 이전, 근로조건변동, 임금체불 등으로 자진퇴사)">12(사업장 이전, 근로조건변동, 임금체불 등으로 자진퇴사)</option>
                <option value="22(폐업,도산)">22(폐업,도산)</option>
                <option value="23(경영상 필요 및 회사불황으로 인원감축 등에 의한 퇴사(해고, 권고사직,명예퇴직 포함))">23(경영상 필요 및 회사불황으로 인원감축 등에 의한 퇴사(해고, 권고사직,명예퇴직 포함))</option>
                <option value="26(근로자의 귀책사유에 의한 징계해고, 권고사직">26(근로자의 귀책사유에 의한 징계해고, 권고사직</option>
                <option value="31(정년)">31(정년)</option>
                <option value="32(계약만료, 공사종료)">32(계약만료, 공사종료)</option>
                <option value="41(고용보험 비적용)">41(고용보험 비적용)</option>
                <option value="42(이중고용)">42(이중고용)</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="out_date">퇴사일</label>
              <v-date-picker
                v-model="out_date"
                id="out_date"
                :errors="errors.out_date"
              />
            </div>
          </div>
          <template  v-if="employee.employee_type !== '사업소득자'">
            <div class="col-md-3">
              <div class="form-group">
                <label for="severance_pay">퇴직금</label>
                <v-input
                  type="number"
                  id="severance_pay"
                  v-model="severance_pay"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="severance_pay_request">퇴직금 계산요청</label><br />
                <input type="checkbox" id="severance_pay_request" v-model="severance_pay_request">
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary" @click="update">
        확인
      </button>
      &nbsp;
      <button type="button" class="btn btn-default" @click="$router.back()">
        목록
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import EmployeeForm from "@/components/employee/manage/EmployeeForm";
  import Index from "@/views/employee/manage/Index";
  import UpdateEmployeeLeaveInfoJob from "@/core/jobs/employee/UpdateEmployeeLeaveInfoJob";
  import {validatedate} from "@/utils/validation";

  export default {
    data() {
      return {
        out_date: '',
        out_reason: '',
        severance_pay: 0,
        errors: {},
        severance_pay_request: false
      };
    },
    computed: {
      ...mapState('employee', {
        employee: state => state.selected_employee
      })
    },
    methods: {
      update() {
        if (! validatedate(this.out_date)) {
          alert('퇴사일을 입력해주세요.');
          return;
        }

        if (this.employee.employee_type === '일반근로자' && this.out_reason === '') {
          alert('퇴사사유를 선택해주세요.');
          return;
        }

        if (!confirm('퇴사처리를 진행하시겠습니까?')) {
          return false;
        }

        dispatch(new UpdateEmployeeLeaveInfoJob(parseInt(this.$route.params.id), {
          out_date: this.out_date,
          out_reason: this.out_reason,
          severance_pay: this.severance_pay,
          severance_pay_request: this.severance_pay_request
        })).then(() => {
          if (this.employee.employee_type === '일반근로자') {
            alert('퇴사신청이 완료되었습니다. 담당자가 신속히 상실신고를 완료 하곘습니다.');
          } else {
            alert('퇴사처리가 완료되었습니다.');
          }

          this.$router.back();
        }).catch(() => {
          alert('필수 입력사항이 누락되었습니다. 입력사항을 확인해 주세요.');
        });
      }
    },
    components: {
      Index,
      EmployeeForm
    }
  }
</script>
