import PrintPayslipView from '@/views/print/payslip/Index.vue';
import PrintPayrollView from '@/views/print/payroll/Index.vue';
import PrintPayrollBusinessView from '@/views/print/payroll-business/Index.vue';

export default [
  {
    path: '/print/payslip',
    component: PrintPayslipView,
    name: 'print.payslip'
  },
  {
    path: '/print/payroll',
    component: PrintPayrollView,
    name: 'print.payroll'
  },
  {
    path: '/print/payroll-business',
    component: PrintPayrollBusinessView,
    name: 'print.payroll-business'
  }
]
