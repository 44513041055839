import {DispatchContext, Job} from "@/core/types";
import {sendResetLink} from "@/core/api/auth";

export default class SendPasswordResetLinkJob implements Job {
  private readonly email: string;

  constructor(email: string) {
    this.email = email;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await sendResetLink(this.email);
  }
}
