<template>
  <div class="container">
    <h1 class="page-title">세무지식</h1>

    <knowhow-show />
  </div>
</template>
<script>
  import KnowhowShow from "@/components/announcement/knowhow/KnowhowShow";
  import SelectAnnouncementJob from "@/core/jobs/announcement/SelectAnnouncementJob";

  export default {
    components: {
      KnowhowShow
    },
    mounted() {
      dispatch(new SelectAnnouncementJob(parseInt(this.$route.params.id)));
    }
  }
</script>
