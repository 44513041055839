import {DispatchContext, Job} from "@/core/types";
import * as payslipApi from "@/core/api/payslip";

export default class SendEmailsJob implements Job {
    private year: string;
    private month: string;
    private employeeIdList: Array<number>;

    constructor(employeeIdList: Array<number>, year: string, month: string) {
        this.employeeIdList = employeeIdList;
        this.year = year;
        this.month = month;
    }

    async run(context: DispatchContext): Promise<any> {
        await payslipApi.sendEmails(this.employeeIdList, this.year, this.month);
    }
}