import * as http from './http';

export interface CreateEmployeeData {
  code: string,
  name: string,
  birthday: string,
  position: string,
  department: string,
  in_date: string,
  employee_type: string,
  pay_type: string,
  jumin: string
}

export interface UpdateEmployeeData {
  name: string,
  birthday: string,
  position: string,
  department: string,
  employee_type: string,
  pay_type: string,
  jumin: string
}

export interface UpdateEmployeeLeaveInfoData {
  out_date: string,
  severance_pay: number | null,
  severance_pay_request: boolean,
  out_reason: string | null
}

export interface SearchEmployeeData {
  search_type: string,
  search_value: string,
  employee_type: Array<string>,
  work_type: Array<string>
}

export function getNotCompletedEmployees() {
  return http.get('/api/v1/employee/not-completed');
}

export function getEmployees(page: number, data: SearchEmployeeData | null = null) {
  const employeeType = data.employee_type.map((employee) => `employee_type[]=${employee}`);
  const workType = data.work_type.map((type) => `work_type[]=${type}`);

  if (data.search_value !== '' && data.search_type !== '') {
    return http.get(`/api/v1/employee?page=${page}&search_type=${data.search_type}&search_value=${data.search_value}&${employeeType.join('&')}&${workType.join('&')}`);
  } else {
    return http.get(`/api/v1/employee?page=${page}&search_value=${data.search_value}&${employeeType.join('&')}&${workType.join('&')}`);
  }
}

export function updateEmployeeLeave(id: number, data: UpdateEmployeeLeaveInfoData) {
  return http.post(`/api/v1/employee/${id}/leave`, {
    out_date: data.out_date,
    out_reason: data.out_reason,
    severance_pay: data.severance_pay,
    severance_pay_request: data.severance_pay_request
  });
}

export function updateEmployee(id: number, data: UpdateEmployeeData) {
  return http.put(`/api/v1/employee/${id}`, data);
}

export function createEmployee(data: CreateEmployeeData) {
  return http.post('/api/v1/employee', data);
}
