import {DispatchContext, Job} from "@/core/types";
import {getNotCompletedEmployees} from "@/core/api/employee";

export default class LoadNotCompletedEmployeesJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    const response = await getNotCompletedEmployees();

    context.store.commit('employee/setNotCompleted', response.data.data);
  }
}
