import {DispatchContext, Job} from "@/core/types";

export default class DownloadAttachmentJob implements Job {
  private readonly attachment: any;

  constructor(attachment: any) {
    this.attachment = attachment;
  }

  async run(context: DispatchContext): Promise<any> {
    const form = document.createElement('form');
    const body = document.getElementsByTagName('body')[0];

    form.method = 'post';
    form.action = this.attachment.file.url;
    form.target = '_blank';
    form.appendChild(this.createHiddenInput('api_token', context.store.state.auth.user.api_token));

    body.appendChild(form);
    form.submit();
    body.removeChild(form);
  }

  createHiddenInput(name: string, value: string) {
    const input = document.createElement('input');

    input.type = 'hidden';
    input.name = name;
    input.value = value;

    return input;
  }
}
