<template>
  <div v-if="user !== null">
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user"></i> 계정 정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="userid">아이디</label>
              <input type="text" class="form-control" id="userid" readonly :value="user.userid">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">회사명</label>
              <v-input
                id="name"
                type="text"
                v-model="userInput.name"
                :errors="errors.name"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="email">이메일</label>
              <v-input
                id="email"
                type="email"
                v-model="userInput.email"
                :errors="errors.email"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="company_number">사업자등록번호</label>
              <v-input
                id="company_number"
                type="text"
                v-model="userInput.company_number"
                :errors="errors.company_number"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group form-inline">
              <label for="pay_day_type">급여지급일</label><br />
              <select class="form-control" :class="{'form-error': errors.pay_day_type !== undefined}" id="pay_day_type" v-model="userInput.pay_day_type">
                <option value="this_month">당월</option>
                <option value="next_month">익월</option>
              </select>
              &nbsp;
              <input type="text" class="form-control" :class="{'form-error': errors.pay_day !== undefined}" placeholder="지급일" size="5" v-model="userInput.pay_day"> 일
              <div class="error-message" v-if="errors.pay_day_type !== undefined || errors.pay_day !== undefined">
                <template v-for="error in errors.pay_day_type">
                  {{ error }} <br />
                </template>
                <template v-for="error in errors.pay_day">
                  {{ error }} <br />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-key"></i> 비밀번호
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="password">비밀번호</label>
              <v-input
                type="password"
                id="password"
                v-model="userInput.password"
                :errors="errors.password"
              />
            </div>
            <div class="form-group">
              <label for="password_confirmation">비밀번호 확인</label>
              <v-input
                type="password"
                id="password_confirmation"
                v-model="userInput.password_confirmation"
                :errors="errors.password_confirmation"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-phone"></i> 연락처 정보
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="phone_number">휴대전화번호</label>
              <div class="input-group">
                <input type="tel" :readonly="issued" class="form-control" :class="{'form-error': errors.phone_number !== undefined}" placeholder="휴대전화번호" id="phone_number" v-model="userInput.phone_number">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-default" @click="sendPhoneCertNumber" :disabled="issued">인증번호 전송</button>
                </div>
              </div>
              <div class="error-message" v-if="errors.phone_number !== undefined">
                <template v-for="error in errors.phone_number">
                  {{ error }}<br />
                </template>
              </div>
            </div>
            <div class="form-group" v-if="issued">
              <label for="phone_number_cert">인증번호</label>
              <div class="input-group">
                <input type="number" class="form-control" :class="{'form-error': errors.phone_cert_number !== undefined}" placeholder="인증번호" id="phone_number_cert" v-model="userInput.phone_cert_number">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-primary" @click="confirmPhoneCertNumber">인증번호 입력</button>
                </div>
              </div>
              <div class="error-message" v-if="errors.phone_cert_number !== undefined">
                <template v-for="error in errors.phone_cert_number">
                  {{ error }}<br />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-primary" @click="update">
        정보 변경
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import SendPhoneCertNumberJob from "@/core/jobs/phone-cert/SendPhoneCertNumberJob";
  import CheckPhoneCertNumberJob from "@/core/jobs/phone-cert/CheckPhoneCertNumberJob";
  import UpdateUserPhoneNumberJob from "@/core/jobs/auth/UpdateUserPhoneNumberJob";
  import UpdateUserJob from "@/core/jobs/auth/UpdateUserJob";

  export default {
    data() {
      return {
        userInput: {
          name: '',
          email: '',
          company_number: '',
          pay_day_type: '',
          pay_day: '',
          phone_number: '',
          phone_cert_number: '',
          password: '',
          password_confirmation: '',
          extend_salary_rate: '',
          night_salary_rate: '',
          holiday_salary_rate: '',
          annuity_tax_rate: '',
          hire_insurance_rate: '',
          health_insurance_rate: '',
          treatment_insurance_rate: ''
        },
        issued: false,
        errors: {}
      };
    },
    computed: {
      ...mapState('auth', {
        user: state => state.user
      })
    },
    methods: {
      update() {
        dispatch(new UpdateUserJob({
          name: this.userInput.name,
          password: this.userInput.password,
          password_confirmation: this.userInput.password_confirmation,
          email: this.userInput.email,
          company_number: this.userInput.company_number,
          pay_day_type: this.userInput.pay_day_type,
          pay_day: this.userInput.pay_day,
        })).then(() => {
          this.userInput.password = '';
          this.userInput.password_confirmation = '';
          alert('정보수정 완료');
        }).catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data;
          }
        });
      },
      sendPhoneCertNumber() {
        if (this.userInput.phone_number === '') {
          alert('휴대전화번호를 입력해주세요.');
          return;
        }

        dispatch(new SendPhoneCertNumberJob(this.userInput.phone_number)).then(() => {
          this.issued = true;
        });
      },
      confirmPhoneCertNumber() {
        if (this.userInput.phone_cert_number === '') {
          alert('인증번호를 입력해주세요.');
          return;
        }

        dispatch(new CheckPhoneCertNumberJob(this.userInput.phone_number, this.userInput.phone_cert_number)).then(() => {
          dispatch(new UpdateUserPhoneNumberJob({
            phone_number: this.userInput.phone_number,
            number: this.userInput.phone_cert_number
          })).then(() => {
            this.userInput.phone_cert_number = '';
            this.issued = false;

            alert('휴대전화 번호 변경 완료');
          });
        }).catch(() => {
          alert('인증번호를 확인해주세요.');
        });
      }
    },
    mounted() {
      this.userInput = {
        name: this.user.name,
        email: this.user.email,
        company_number: this.user.company_number,
        pay_day_type: this.user.pay_day_type,
        pay_day: this.user.pay_day,
        phone_number: this.user.phone_number,
        password: '',
        password_confirmation: '',
      };
    }
  }
</script>
