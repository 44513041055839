<template>
  <div>
    <footer class="app-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h4>호연 사업자정보</h4>
            <p>
              호연회계법인 ㅣ 서울특별시 강남구 영동대로 611, 찬앤찬타워 9층<br />
              대표 : 김재문  ㅣ  개인정보관리책임자 : 최학수<br />
              대표 번호 : 02-6205-4070<br />
              사업자등록번호 : 528-81-00356
            </p>
          </div>
          <div class="col-md-offset-4 col-md-2">
            <h4>고객센터</h4>
            <ul class="customer-center">
              <li>
                <router-link
                  :to="{name: 'announcement.notice.index'}"
                >
                  공지사항
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{name: 'terms.usage'}"
                >
                  이용약관
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{name: 'terms.privacy'}"
                >
                  개인정보처리방침
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
  export default {

  }
</script>
