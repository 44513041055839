<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 사원 목록
      </div>
      <div class="card-body">
        <search-form
          :value="searchInput"
          @input="updateSearchInput"
          @search="search"
        />
        <table class="table table-bordered employee-list">
          <thead>
          <tr>
            <th style="width: 50px;" class="hidden-xs"></th>
            <th class="hidden-xs">사원코드</th>
            <th>이름</th>
            <th style="width: 90px;" class="hidden-xs">생년월일</th>
            <th class="hidden-xs">직급</th>
            <th class="hidden-xs">부서</th>
            <th style="width: 90px;" class="hidden-xs">근무타입</th>
            <th style="width: 90px;" class="hidden-xs">입사일</th>
            <th style="width: 90px;" class="hidden-xs">퇴사일</th>
            <th style="width: 70px;">현재 상태</th>
            <th style="width: 130px;"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(employee, index) in employees">
            <tr :class="{out: employee.out_date !== null}">
              <td align="center" class="hidden-xs">{{ total - (per_page * (page - 1)) - index }}</td>
              <td class="hidden-xs">{{ employee.code }}</td>
              <td>{{ employee.name }}</td>
              <td class="hidden-xs">{{ employee.birthday }}</td>
              <td class="hidden-xs">{{ employee.position }}</td>
              <td class="hidden-xs">{{ employee.department }}</td>
              <td class="hidden-xs">{{ employee.employee_type }}</td>
              <td class="hidden-xs">{{ employee.in_date }}</td>
              <td class="hidden-xs">{{ employee.out_date }}</td>
              <td>
                {{ employee.out_date !== null ? '퇴사자' : '재직중'}}
              </td>
              <td>
                <router-link
                  :to="{name: 'employee.manage.edit', params: {id: employee.id}}"
                  class="btn btn-default"
                >
                  확인
                </router-link>
                &nbsp;
                <router-link
                  :to="{name: 'employee.manage.leave', params: {id: employee.id}}"
                  class="btn btn-default"
                  v-if="employee.out_date === null"
                >
                  퇴사
                </router-link>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <pagination
          :total="total"
          :per-page="per_page"
          :page="page"
          @move-page="movePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import LoadEmployeesJob from "@/core/jobs/employee/LoadEmployeesJob";
  import SearchForm from "@/components/employee/manage/SearchForm";

  export default {
    computed: {
      ...mapState('employee', {
        searchInput: state => state.search,
        employees: state => state.employees,
        page: state => state.page,
        per_page: state => state.per_page,
        total: state => state.total
      })
    },
    methods: {
      updateSearchInput(v) {
        this.$store.commit('employee/setSearchValue', {
          search_type: v.search_type,
          search_value: v.search_value,
          employee_type: v.employee_type,
          work_type: v.work_type
        });
      },
      search() {
        dispatch(new LoadEmployeesJob(this.page));
      },
      movePage(page) {
        dispatch(new LoadEmployeesJob(page));
      }
    },
    components: {
      SearchForm
    }
  }
</script>
