import {DispatchContext, Job} from "@/core/types";

import * as payslipApi from '@/core/api/payslip';

export default class SendEmailJob implements Job {
  private employeeId: number;
  private year: string;
  private month: string;

  constructor(employeeId: number, year: string, month: string) {
    this.employeeId = employeeId;
    this.year = year;
    this.month = month;
  }

  async run(context: DispatchContext): Promise<any> {
    await payslipApi.sendEmail(this.employeeId, this.year, this.month);
  }
}
