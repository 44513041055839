<template>
  <div class="auth-form">
    <register-form />
  </div>
</template>
<script>
  import RegisterForm from "@/components/auth/RegisterForm";

  export default {
    components: {
      RegisterForm
    }
  }
</script>
