<template>
  <div class="auth-form">
    <reset-password-form />
  </div>
</template>
<script>
  import ResetPasswordForm from "@/components/auth/ResetPasswordForm";

  export default {
    components: {
      ResetPasswordForm
    }
  }
</script>
