<template>
  <div class="main-banner">
    <div class="main-banner-wrapper">
      <div v-if="user !== null">
        <div class="welcome-name"><span class="highlight">{{ user.name }}</span>님</div>
        <div class="welcome-text">환영합니다.</div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('auth', {
        user: state => state.user
      })
    }
  }
</script>
