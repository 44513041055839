<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-xs-6">
          <h1 class="page-title">급여관리</h1>
        </div>
        <div class="col-xs-6 text-right">
          <button
            style="margin-top: 20px;"
            type="button"
            class="btn btn-primary"
            @click="submit"
            v-if="payslipEditable"
          >
            급여 제출하기
          </button>
          <button
            style="margin-top: 20px; margin-right: 5px;"
            type="button"
            class="btn btn-primary"
            v-if="! payslipEditable"
            @click="cancel"
            >
            급여제출 취소요청
          </button>
          <button
            style="margin-top: 20px;"
            type="button"
            class="btn btn-default"
            disabled
            v-if="! payslipEditable"
            >
            급여제출 완료
          </button>
        </div>
      </div>
      <ul class="list-inline">
        <li>
          <router-link
            :to="{name: 'payslip.employee.index'}"
            class="btn"
            :class="getBtnActiveClass('payslip.employee.index')"
          >
            일반근로자
          </router-link>
        </li>
        <li>
          <router-link
            :to="{name: 'payslip.business.index'}"
            class="btn"
            :class="getBtnActiveClass('payslip.business.index')"
          >
            사업소득자
          </router-link>
        </li>
        <li>
          <router-link
            :to="{name: 'payslip.daily.index'}"
            class="btn"
            :class="getBtnActiveClass('payslip.daily.index')"
          >
            일용근로자
          </router-link>
        </li>
      </ul>
    </div>

    <router-view />
  </div>
</template>
<script>
  import TopSubMenu from "@/components/layouts/TopSubMenu.vue";
  import {mapGetters, mapState} from 'vuex';
  import SubmitPayslipJob from "@/core/jobs/payslip/SubmitPayslipJob";
  import LoadPayslipJob from "@/core/jobs/payslip/LoadPayslipJob";
  import moment from 'moment';
  import CancelRequestJob from "@/core/jobs/payslip/CancelRequestJob";

  export default {
    data() {
      return {
        menus: [
          {
            label: '일반 근로자',
            link: 'payslip.employee.index'
          },
          {
            label: '사업소득자',
            link: 'payslip.business.index'
          },
          {
            label: '일용근로자',
            link: 'payslip.daily.index'
          }
        ]
      };
    },
    computed: {
      ...mapState('payslip',{
        payslip: state => state.payslip
      }),
      ...mapGetters('payslip', {
        payslipEditable: 'payslipEditable'
      }),
      year() {
        let year = this.$route.query.year;

        if (year === undefined) {
          year = moment().format('YYYY');
        }

        return year;
      },
      month() {
        let month = this.$route.query.month;

        if (month === undefined) {
          month = moment().format('MM');
        }

        return month;
      }
    },
    methods: {
      cancel() {
        if (this.payslip.state === 'user_cancel') {
          alert('취소요청을 처리중입니다.');
          return;
        }

        if (! confirm('정말 취소요청을 하시겠습니까?')) {
          return;
        }

        dispatch(new CancelRequestJob(this.year, this.month)).then(() => {
          alert('취소요청을 완료했습니다.');
          dispatch(new LoadPayslipJob(this.year, this.month));
        });
      },
      getBtnActiveClass(routeName) {
        if (routeName === this.$route.name) {
          return 'btn-primary';
        }

        return 'btn-default';
      },
      submit() {
        if (! confirm('근로기준법 시행령 제27조의2에 따른 임금명세서 필수 기재사항에 대한 작성책임은 사업장에게 있음을 확인합니다.')) {
          return;
        }

        if (! confirm('급여를 제출하시면 해당월의 급여 수정이 더 이상 불가 합니다.\n입력된 급여가 정확한지 다시한번 확인해 주시기 바랍니다.\n급여를 담당자에게 제출하시겠습니까?')) {
          return;
        }

        dispatch(new SubmitPayslipJob(this.year, this.month))
          .then(() => {
            alert('급여 제출이 완료되었습니다. 담당자가 신속히 급여 계산을 완료 하겠습니다.');
            dispatch(new LoadPayslipJob(this.year, this.month));
          })
          .catch(err => {
            alert('급여가 입력되지 않았습니다.');
          });
      }
    },
    components: {
      TopSubMenu
    }
  }
</script>
