<template>
  <div>
    <input
      :type="type"
      :class="inputClass"
      :placeholder="placeholder"
      :id="id"
      :value="value"
      @input="changed($event)"
      @keypress="keypress($event)"
      :readonly="readonly !== undefined"
      ref="input"
    >
    <template v-if="errors !== undefined">
      <div :class="{'error-message': errorMessages.length > 0}">
        <template v-for="error in errorMessages">
          {{ error }} <br />
        </template>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    props: ['type', 'className', 'placeholder', 'id' ,'value', 'errors', 'readonly'],
    computed: {
      errorMessages() {
        if (this.errors === undefined) {
          return [];
        }

        return this.errors;
      },
      inputClass() {
        if (this.errorMessages.length > 0) {
          return [
            this.className,
            'form-control',
            'form-error'
          ];
        }

        return [
          'form-control',
          this.className
        ];
      }
    },
    methods: {
      keypress(event) {
        this.$emit('keypress', event);
      },
      changed(event) {
        this.$emit('input', event.target.value);
      }
    }
  }
</script>
