<template>
  <div class="print-payroll">
    <h2>{{ year }}년 {{ month }}월분 사업소득대장</h2>
    <table>
      <colgroup>
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
      </colgroup>
      <tr>
        <td>{{ user.name }}</td>
        <td>정렬 : 코드순</td>
        <td>[귀속 : {{ year }}년 {{ month }}월]</td>
        <td>[지급 : {{ payDate }}]</td>
      </tr>
    </table>
    <br />
    <table>
      <colgroup>
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="6.5%">
        <col width="*">
      </colgroup>
      <thead>
      <tr>
        <th colspan="2">인적사항</th>
        <th colspan="7">기 본 급 여 및 제 수 당</th>
        <th colspan="6">공 제 및 차 인 금 액</th>
        <th rowspan="4">영<br />수<br />인</th>
      </tr>
      <tr>
        <th>사원번호</th>
        <th>성명</th>
        <template v-for="(num, index) in 7">
          <th>
            {{ wages.length > index ? wages[index].name : '' }}
          </th>
        </template>
        <template v-for="(num, index) in 6">
          <th>
            {{ excludes.length > index ? excludes[index].name : '' }}
          </th>
        </template>
      </tr>
      <tr>
        <th>입사일</th>
        <th>직급</th>
        <template v-for="(num, index) in 7">
          <th>
            {{ wages.length > (index + 7) ? wages[index + 7].name : '' }}
          </th>
        </template>
        <template v-for="(num, index) in 5">
          <th>
            {{ excludes.length > (index + 6) ? excludes[index + 6].name : '' }}
          </th>
        </template>
        <th>공제합계</th>
      </tr>
      <tr class="line-separator">
        <th>퇴사일</th>
        <th>부서</th>
        <template v-for="(num, index) in 6">
          <th>
            {{ wages.length > (index + 14) ? wages[index + 14].name : '' }}
          </th>
        </template>
        <th>지급합계</th>
        <template v-for="(num, index) in 5">
          <th>
            {{ excludes.length > (index + 11) ? excludes[index + 11].name : '' }}
          </th>
        </template>
        <th>차인지급액</th>
      </tr>
      <template v-for="employee in employees">
        <tr>
          <td align="center">{{ employee.code }}</td>
          <td align="center">{{ employee.name }}</td>
          <template v-for="(num, index) in 7">
            <td align="right" :class="`1-${num}`">{{ wages.length > index ? getPropertyValue(employee.payslip_values, wages[index].id).format() : '' }}</td>
          </template>
          <template v-for="(num, index) in 6">
            <td align="right" :class="`1-${num + 7}`">{{ excludes.length > index ? getPropertyValue(employee.payslip_values, excludes[index].id).format() : '' }}</td>
          </template>
          <td rowspan="3"></td>
        </tr>

        <tr>
          <td align="center">{{ employee.in_date }}</td>
          <td align="center">{{ employee.position }}</td>
          <template v-for="(num, index) in 7">
            <td align="right" :class="`2-${num}`">{{ wages.length > (index + 7) ? getPropertyValue(employee.payslip_values, wages[index + 7].id).format() : '' }}</td>
          </template>
          <template v-for="(num, index) in 5">
            <td align="right" :class="`2-${num + 7}`">{{ excludes.length > (index + 6) ? getPropertyValue(employee.payslip_values, excludes[index + 6].id).format() : '' }}</td>
          </template>
          <td align="right" class="2-13">{{ employee.total_excludes.format() }}</td>
        </tr>

        <tr class="line-separator">
          <td align="center">{{ employee.out_date }}</td>
          <td align="center">{{ employee.department }}</td>
          <template v-for="(num, index) in 6">
            <td align="right" :class="`3-${num}`">{{ wages.length > (index + 14) ? getPropertyValue(employee.payslip_values, wages[index + 14].id).format() : '' }}</td>
          </template>
          <td align="right" class="3-7">{{ employee.total_wages.format() }}</td>
          <template v-for="(num, index) in 5">
            <td align="right" :class="`3-${num + 7}`">{{ excludes.length > (index + 11) ? getPropertyValue(employee.payslip_values, excludes[index + 11].id).format() : '' }}</td>
          </template>
          <td align="right" class="3-13">{{ (employee.total_wages - employee.total_excludes).format() }}</td>
        </tr>
      </template>
      <tr>
        <th colspan="2" rowspan="3">합계({{ payslip.employees.length }}명)</th>
        <template v-for="v in values[0]">
          <td align="right">{{ v.format() }}</td>
        </template>
        <td rowspan="3"></td>
      </tr>
      <tr>
        <template v-for="v in values[1]">
          <td align="right">{{ v.format() }}</td>
        </template>
      </tr>
      <tr>
        <template v-for="v in values[2]">
          <td align="right">{{ v.format() }}</td>
        </template>
      </tr>
      </thead>
    </table>
  </div>
</template>
<script>
  import moment from 'moment';
  import {mapState} from 'vuex';
  import PayrollFirst from "./PayrollFirst";
  import PayrollSecond from "./PayrollSecond";
  import PayrollThird from "./PayrollThird";

  export default {
    data() {
      return {
        values: []
      };
    },
    computed: {
      ...mapState('auth', {
        user: state => state.user
      }),
      ...mapState('invoice', {
        payslip: state => state.payslip,
        year: state => state.year,
        month: state => state.month
      }),
      employees() {
        return this.payslip.employees.filter(e => e.employee_type === '사업소득자');
      },
      payDate() {
        return moment(this.payslip.pay_date, 'YYYY-MM-DD').format('YYYY년 MM월 DD일');
      },
      wages() {
        return [
          {
            id: 1,
            name: '기본급'
          },
          ...this.payslip.wages
        ];
      },
      excludes() {
        return this.payslip.excludes;
      }
    },
    methods: {
      getPropertyValue(payslipValues, propertyId) {
        const d = payslipValues.filter(v => v.base_property_id === propertyId);

        if (d.length === 0) return 0;
        return d[0].value;
      }
    },
    mounted() {
      for (let i = 1; i <= 3; i ++) {
        let values = [];

        for (let j = 1; j <= 13; j ++) {
          let elements = document.getElementsByClassName(`${i}-${j}`);
          let sum = 0;

          for (let element of elements) {
            let v = element.innerText;

            if (v === '') {
              v = 0;
            } else {
              v = parseInt(v.replace(/,/gi, ''));
            }

            sum += v;
          }

          values.push(sum);
        }

        this.values.push(values);
        this.$nextTick(() => {
          window.print();
        });
      }
    },
    components: {
      PayrollFirst,
      PayrollSecond,
      PayrollThird
    }
  }
</script>
