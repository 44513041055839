<template>
  <div>
    <div class="card" v-if="payslip.state === 'admin_confirmed'">
      <div class="card-heading">
        <i class="fa fa-check"></i> 금액 확정
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <select class="form-control" v-model="type">
                <option value="fix">확정</option>
                <option value="reject">수정요청</option>
              </select>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group" v-if="type === 'reject'">
              <input type="text" class="form-control" v-model="reason">
            </div>
          </div>
        </div>
        <div class="alert alert-danger">
          급여금액 수정이 필요한 경우 수정요청을 눌러주시기 바랍니다.
        </div>
        <div class="text-right">
          <button type="button" class="btn btn-primary" @click="save">
            확인
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        type: 'fix',
        reason: ''
      }
    },
    computed: {
      ...mapState('invoice', {
        payslip: state => state.payslip
      })
    },
    methods: {
      save() {
        this.$emit('save', {
          type: this.type,
          reason: this.reason
        });
      }
    }
  }
</script>
