<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user-tie"></i> 사원정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="code">사원코드</label>
              <input type="text" class="form-control" id="code" readonly :value="employee.code">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="name">이름</label>
              <input type="text" class="form-control" id="name" readonly :value="employee.name">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="birthday">생년월일</label>
              <input type="text" class="form-control" id="birthday" readonly :value="employee.birthday">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="position">직급</label>
              <input type="text" class="form-control" id="position" readonly :value="employee.position">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="department">부서</label>
              <input type="text" class="form-control" id="department" readonly :value="employee.department">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="in_date">입사일</label>
              <input type="text" class="form-control" id="in_date" readonly :value="employee.in_date">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="employee_type">근무타입</label>
              <input type="text" class="form-control" id="employee_type" readonly :value="employee.employee_type">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="pay_type">급여타입</label>
              <input type="text" class="form-control" id="pay_type" readonly :value="employee.pay_type">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['employee']
  }
</script>
