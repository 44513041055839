import {DispatchContext, Job} from "@/core/types";
import {Employee} from "@/core/types/model";

export default class SelectEmployeeJob implements Job {
  private readonly id: number;

  constructor(id: number) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    const employees = context.store.state.employee.employees.filter((employee: Employee) => employee.id === this.id);

    context.store.commit('employee/selectEmployee', employees[0]);
  }
}
