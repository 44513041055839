import {DispatchContext, Job} from "@/core/types";
import {getEmployees} from "@/core/api/payslip";

export default class GetPayslipEmployeesJob implements Job {
  private readonly page: number;
  private readonly year: string;
  private readonly type: string;
  private readonly month: string;

  constructor(page: number, year: string, month: string, type: string) {
    this.month = month;
    this.page = page;
    this.year = year;
    this.type = type;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getEmployees(this.page, {
      type: this.type,
      year: this.year,
      month: this.month
    });

    context.store.commit('payslip/setEmployees', response.data.data);
  }
}
