<template>
  <div>
    <employee-form
      v-model="userInput"
      :errors="errors"
    />
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-hands-helping"></i> 피부양자
      </div>
      <div class="card-body">
        <dependent-form
          v-model="dependents"
        />
      </div>
    </div>
    <div class="alert alert-danger">
      피부양자를 등록한 경우 반드시 피부양자를 입증할 수 있는 가족관계증명서를 첨부해 주세요.
    </div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-file-upload"></i>가족관계증명서
      </div>
      <div class="card-body">
        <v-input-file
          id="dependent_file"
          v-model="dependentFile"
        />
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-address-card"></i> 입사 정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="in_date">입사일</label>
              <v-date-picker
                v-model="in_date"
                id="in_date"
                :errors="errors.in_date"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary" @click="create">
        확인
      </button>
    </div>
  </div>
</template>
<script>
  import EmployeeForm from '@/components/employee/manage/EmployeeForm';
  import CreateEmployeeJob from "@/core/jobs/employee/CreateEmployeeJob";
  import DependentForm from "@/components/employee/manage/DependentForm";

  export default {
    data() {
      return {
        dependentFile: null,
        dependents: [
        ],
        userInput: {
          code: '',
          name: '',
          birthday: '',
          position: '',
          department: '',
          employee_type: '',
          pay_type: '',
          jumin: '',
          working_hour: '',
          income: '',
          role: '',
          is_contract: false,
          phone_number: '',
          nation: '',
          stay_condition: '',
          email: '',
          contract_from: '',
          contract_to: ''
        },
        in_date: '',
        errors: {}
      }
    },
    methods: {
      create() {
        if (!confirm('입사자를 추가하시겠습니까?')) {
          return false;
        }

        let working_hour = this.userInput.working_hour;
        let income = this.userInput.income;

        if (this.userInput.employee_type !== '일반근로자') {
          working_hour = 0;
          income = 0;
        }

        dispatch(new CreateEmployeeJob({
          code: this.userInput.code,
          name: this.userInput.name,
          birthday: this.userInput.birthday,
          position: this.userInput.position,
          department: this.userInput.department,
          employee_type: this.userInput.employee_type,
          pay_type: this.userInput.pay_type,
          jumin: this.userInput.jumin,
          in_date: this.in_date,
          working_hour,
          income,
          dependents: this.dependents,
          is_contract: this.userInput.is_contract,
          role: this.userInput.role,
          phone_number: this.userInput.phone_number,
          nation: this.userInput.nation,
          stay_condition: this.userInput.stay_condition,
          dependent_file: this.dependentFile,
          email: this.userInput.email,
          contract_from: this.userInput.contract_from,
          contract_to: this.userInput.contract_to
        })).then(() => {
          this.dependentFile = null;
          if (this.userInput.employee_type === '일반근로자') {
            alert('입사자 추가가 완료되었습니다. 담당자가 신속히 취득신고를 완료 하곘습니다.');
          } else {
            alert('입사자 추가가 완료되었습니다.');
          }

          this.$router.push({
            name: 'employee.manage.index'
          });
        }).catch(err => {
          if (err.response.status === 422) {
            alert('필수 입력사항이 누락되었습니다. 입력사항을 확인해 주세요.');
            this.errors = err.response.data;
          }
        });
      }
    },
    components: {
      EmployeeForm,
      DependentForm
    }
  }
</script>
