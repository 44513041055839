<template>
  <div class="invoice-detail" v-if="employee !== null">
    <div class="invoice-detail-header">
      <h5>{{ year }}년 {{ month }}월 급여지급 명세서</h5>
      <h4>사원명 : {{ employee.name }}</h4>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-plus"></i> 급여항목
          </div>
          <div class="card-body">
            <table class="table table-bordered">
              <colgroup>
                <col width="*" />
                <col width="200" />
              </colgroup>
              <thead>
              <tr>
                <th>급여항목</th>
                <th>금액</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="property in payslipValues">
                <tr>
                  <td>{{ property.name }}</td>
                  <td align="right">{{ property.value.format() }}</td>
                </tr>
              </template>
              <tr>
                <th>합계</th>
                <th style="text-align: right">{{ payslipValues.reduce((a, b) => a + b.value, 0).format() }}</th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-minus"></i> 공제항목
          </div>
          <div class="card-body">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>공제항목</th>
                <th>금액</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="property in payslipExcludes">
                <tr>
                  <td>{{ property.name }}</td>
                  <td align="right">{{ property.value.format() }}</td>
                </tr>
              </template>
              <tr>
                <th>합계</th>
                <th style="text-align: right;">{{ payslipExcludes.reduce((a, b) => a + b.value, 0).format() }}</th>
              </tr>
              <tr>
                <th>차인지급액</th>
                <th style="text-align: right;">{{ (totalWageValue - totalExcludeValue).format() }}</th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button
        type="button"
        class="btn btn-primary"
        @click="sendEmail(employee)"
      >
        이메일 전송
      </button>
      &nbsp;
      <button
        type="button"
        class="btn btn-primary"
        @click="downloadPayslip(employee)">
        급여명세서 다운로드
      </button>
      &nbsp;
      <button type="button" class="btn btn-default" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import DownloadPayslipJob from "@/core/jobs/payslip/DownloadPayslipJob";
  import SendEmailJob from "@/core/jobs/payslip/SendEmailJob";

  export default {
    computed: {
      ...mapState('invoice', {
        employee: state => state.selectedEmployee,
        year: state => state.year,
        month: state => state.month
      }),
      totalWageValue() {
        return this.payslipValues.reduce((a, b) => a + b.value, 0);
      },
      totalExcludeValue() {
        return this.employee.payslip_values.filter(v => v.type === 'exclude').reduce((a, b) => a + b.value, 0);
      },
      payslipExcludes() {
        return this.employee.payslip_values.filter(v => v.type === 'exclude');
      },
      payslipValues() {
        if (this.employee.employee_type === '사업소득자') {
          return this.employee.payslip_values.filter(v => v.base_property_id === 1).map(v => {
            return {
              ...v,
              name: '지급액'
            }
          });
        }

        return this.employee.payslip_values.filter(v => v.type === 'wage' && v.base_property_id !== 1000 && v.base_property_id !== 1001);
      }
    },
    methods: {
      sendEmail(employee) {
        if (! confirm('급여명세서를 이메일로 전송하시겠습니까?')) {
          return;
        }

        dispatch(new SendEmailJob(employee.id, this.year, this.month)).then(() => {
          alert('이메일 전송이 완료되었습니다.');
        }).catch(() => {
          alert('이메일 전송 중 에러가 발생하였습니다. 관리자에게 문의하여주세요.')
        });
      },
      downloadPayslip(employee) {
        dispatch(new DownloadPayslipJob(this.year, this.month, employee.id));
      }
    }
  }
</script>
