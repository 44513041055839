<template>
  <div class="container">
    <h1 class="page-title">마이페이지</h1>
    <div class="full-page">
      <user-form />
    </div>
  </div>
</template>
<script>
  import UserForm from "@/components/my-page/UserForm";

  export default {
    components: {
      UserForm
    }
  }
</script>
