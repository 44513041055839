import {DispatchContext, Job} from "@/core/types";
import {getWageProperties} from "@/core/api/payslip";

export default class GetWagePropertiesJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    const response = await getWageProperties();

    context.store.commit('payslip/setWageProperties', response.data.data);
  }
}
