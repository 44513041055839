import {DispatchContext, Job} from "@/core/types";

export default class SelectInvoiceEmployeeJob implements Job {
  private readonly id: number;

  constructor(id: number) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    const employee = context.store.state.invoice.payslip.employees.filter((e: any) => e.id === this.id);

    context.store.commit('invoice/selectEmployee', {
      ...employee[0]
    });
  }
}
