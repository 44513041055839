<template>
  <div class="auth-form">
    <reset-confirm-form />
  </div>
</template>
<script>
  import ResetConfirmForm from "@/components/auth/ResetConfirmForm";

  export default {
    components: {
      ResetConfirmForm
    }
  }
</script>
