<template>
  <div>
    <select
      ref="input"
      :id="id"
      :value="value"
      class="form-control"
      :class="{'form-error': errors !== undefined}"
      @change="input"
      @input="input">
      <slot />
    </select>
    <div class="error-message" v-if="errors !== undefined">
      <template v-for="error in errors">
        {{ error }}<br />
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['errors', 'value', 'id'],
    methods: {
      input(v) {
        this.$emit('input', v.target.value);
      }
    }
  }
</script>
