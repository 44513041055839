import {Employee} from "@/core/types/model";

export default {
  namespaced: true,
  state: {
    payslip: null,
    year: 0,
    month: 0,
    selectedPayslip: null,
    attachments: [],
    selectedEmployee: null
  },
  getters: {
    declarationAttachment(state: any) {
      const attachments = state.attachments.filter((v: any) => v.type === 'withholding_tax_declaration');

      if (attachments.length === 0) {
        return null;
      }

      return attachments[0];
    },
    withholdingTaxAttachment(state: any) {
      const attachments = state.attachments.filter((v: any) => v.type === 'withholding_tax_payment');

      if (attachments.length === 0) {
        return null;
      }

      return attachments[0];
    },
    specialTaxAttachment(state: any) {
      const attachments = state.attachments.filter((v: any) => v.type === 'special_tax_payment');

      if (attachments.length === 0) {
        return null;
      }

      return attachments[0];
    },
    normalEmployees(state: any) {
      if (state.payslip === null) {
        return [];
      }

      return state.payslip.employees.filter((employee: any) => employee.employee_type === '일반근로자');
    },
    dailyEmployees(state: any) {
      if (state.payslip === null) {
        return [];
      }

      return state.payslip.employees.filter((employee: any) => employee.employee_type === '일용근로자');
    },
    businessEmployees(state: any) {
      if (state.payslip === null) {
        return [];
      }

      return state.payslip.employees.filter((employee: any) => employee.employee_type === '사업소득자');
    }
  },
  mutations: {
    setAttachments(state: any, data: any) {
      state.attachments = [
        ...data
      ];
    },
    selectEmployee(state: any, data: any) {
      state.selectedEmployee = {
        ...data
      };
    },
    setPayslip(state: any, { year, month, data }: any) {
      if (data === undefined) {
        state.payslip = null;
      } else {
        state.payslip = {
          ...data
        };
      }
      state.year = year;
      state.month = month;
    }
  }
}
