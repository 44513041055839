<template>
  <tr class="line-separator">
    <td align="center">{{ employee.out_date }}</td>
    <td align="center">{{ employee.department }}</td>
    <template v-for="(num, index) in 6">
      <td align="right" :class="`3-${num}`">{{ wages.length > (index + 14) ? wages[index + 14].value.format() : '' }}</td>
    </template>
    <td align="right" class="3-7">{{ totalWages.format() }}</td>
    <template v-for="(num, index) in 5">
      <td align="right" :class="`3-${num + 7}`">{{ excludes.length > (index + 11) ? excludes[index + 11].value.format() : '' }}</td>
    </template>
    <td align="right" class="3-13">{{ (totalWages - totalExcludes).format() }}</td>
  </tr>
</template>
<script>
  export default {
    props: ['employee', 'payslip'],
    computed: {
      wages() {
        return this.employee.payslip_values.filter(v => v.type === 'wage' && v.name !== '근무시간');
      },
      excludes() {
        return this.employee.payslip_values.filter(v => v.type === 'exclude');
      },
      totalWages() {
        return this.wages.reduce((a, b) => a + b.value, 0);
      },
      totalExcludes() {
        return this.excludes.reduce((a, b) => a + b.value, 0);
      }
    }
  }
</script>
