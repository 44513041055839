<template>
  <tr>
    <td align="center">{{ employee.code }}</td>
    <td align="center">{{ employee.name }}</td>
    <template v-for="(num, index) in 7">
      <td align="right" :class="`1-${num}`">{{ wages.length > index ? wages[index].value.format() : '' }}</td>
    </template>
    <template v-for="(num, index) in 6">
      <td align="right" :class="`1-${num + 7}`">{{ excludes.length > index ? excludes[index].value.format() : '' }}</td>
    </template>
    <td rowspan="3"></td>
  </tr>
</template>
<script>
  export default {
    props: ['employee'],
    computed: {
      wages() {
        return this.employee.payslip_values.filter(v => v.type === 'wage' && v.name !== '근무시간');
      },
      excludes() {
        return this.employee.payslip_values.filter(v => v.type === 'exclude');
      }
    }
  }
</script>
