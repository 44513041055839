<template>
  <div class="container">
    <employee-list />
  </div>
</template>
<script>
  import EmployeeList from "@/components/payslip/business/EmployeeList";

  export default {
    components: {
      EmployeeList
    }
  }
</script>
