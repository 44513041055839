import {DispatchContext, Job} from "@/core/types";

export default class SelectAnnouncementJob implements Job {
  private readonly id: number;

  constructor(id: any) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    if (this.id === null) {
      context.store.commit('announcement/selectAnnouncement', null);
    } else {
      const announcements = context.store.state.announcement.announcements.filter((v: any) => v.id === this.id);

      context.store.commit('announcement/selectAnnouncement', announcements[0]);
    }
  }
}
