import Page from '@/views/payslip/Page.vue';

import EmployeeIndexView from '@/views/payslip/employee/Index.vue';
import BusinessIndexView from '@/views/payslip/business/Index.vue';
import BusinessEditView from '@/views/payslip/business/Edit.vue';

import DailyIndexView from '@/views/payslip/daily/Index.vue';

export default [
  {
    path: '/payslip',
    component: Page,
    children: [
      {
        path: 'business',
        component: BusinessIndexView,
        name: 'payslip.business.index'
      },
      {
        path: 'business/:id',
        component: BusinessEditView,
        name: 'payslip.business.edit'
      },
      {
        path: '',
        component: EmployeeIndexView,
        name: 'payslip.employee.index'
      },
      {
        path: 'daily',
        component: DailyIndexView,
        name: 'payslip.daily.index'
      }
    ]
  }
];
