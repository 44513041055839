require('./assets/icon.png');
// @ts-ignore
import jscookie from 'js-cookie';
import SetUserFromTokenJob from "@/core/jobs/auth/SetUserFromTokenJob";
import store from '@/store';
import router from '@/router';
import LoginJob from "@/core/jobs/auth/LoginJob";
import postMessage from "@/core/post-message";
import History from './plugins/vue-router-back-button/src/history';
import {setToken} from "@/core/auth/manager";

if (process.env.NODE_ENV === 'production' && location.protocol != 'https:') {
  location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

let backButtonCount = 0;

function eventListener(event: any) {
  try {
    // @ts-ignore
    const data: any = JSON.parse(event.data);

    if (data.type === 'setToken') {
      setToken(data.token, data.expiresIn);
      store.commit('app/setIsApp', true);
      router.replace({name: 'dashboard'});
    } else if (data.type === 'regist') {
      store.commit('app/setDeviceToken', data.deviceToken);
    } else if (data.type === 'login') {
      // @ts-ignore
      dispatch(new LoginJob(data.userid, data.password, false)).then(() => {
        router.push({name: 'dashboard'});
      });
    } else if (data.type === 'back') {
      if (History.getCurrent() === 0 || History.previous().path === '/auth/login' || History.previous().path === '/empty') {
      } else {
        backButtonCount = 0;

        router.back();
      }
    } else if (data.type === 'androidBack') {
      if (History.getCurrent() === 0 || History.previous().path === '/auth/login' || History.previous().path === '/empty') {
        backButtonCount ++;
        postMessage(JSON.stringify({
          type: 'last-page',
          backButtonCount
        }));
      } else {
        backButtonCount = 0;

        router.back();
      }
    } else if (data.type === 'forward') {
      router.forward();
    } else if (data.type === 'reload') {
      let query = {
        ...router.currentRoute.query
      };

      // @ts-ignore
      query.timestamp = (new Date()).getTime();

      router.push({
        name: router.currentRoute.name,
        query,
        params: router.currentRoute.params
      })
    } else if (data.type === 'home') {
      router.push({
        name: 'dashboard'
      });
    } else if (data.type === 'setting') {
      router.push({
        name: 'setting'
      });
    }
  } catch (e) {
  }
}

window.addEventListener('message', eventListener);
document.addEventListener('message', eventListener);

document.documentElement.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, false);

let lastTouchEnd = 0;
document.documentElement.addEventListener('touchend', function (event) {
  let now = (new Date()).getTime();

  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }

  lastTouchEnd = now;
}, false);

window.onload = function() {
  postMessage(JSON.stringify({
    type: 'loaded'
  }));
};

router.afterEach((to, from) => {
  postMessage(JSON.stringify({
    type: 'navStateChange',
    navState: { routeName: to.name }
  }));
});

export default async function() {
  const token = jscookie.get('user-token');

  if (token !== undefined) {
    // @ts-ignore
    return dispatch(new SetUserFromTokenJob()).catch(err => {
      jscookie.remove('user-token');
    });
  }
}
