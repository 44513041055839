<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-search"></i> 사원 검색
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4 col-md-2">
            <select class="form-control" :value="value.search_type" @input="update" ref="type">
              <option value="code">사원코드</option>
              <option value="name">이름</option>
            </select>
            <br />
          </div>
          <div class="col-sm-8 col-md-10">
            <input type="text" class="form-control" :value="value.search_value" @input="update" ref="value">
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label style="margin-right: 5px;">
                <input type="checkbox" value="재직중" v-model="employee_type"> 재직중
              </label>
              <label>
                <input type="checkbox" value="퇴사자" v-model="employee_type"> 퇴사자
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label style="margin-right: 5px;">
                <input type="checkbox" value="일반근로자" v-model="work_type"> 일반근로자
              </label>
              <label>
                <input type="checkbox" value="사업소득자" v-model="work_type"> 사업소득자
              </label>
              <label>
                <input type="checkbox" value="일용근로자" v-model="work_type"> 일용근로자
              </label>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button type="button" class="btn btn-primary" @click="search">검색</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['value'],
    data() {
      return {
        employee_type: [],
        work_type: []
      };
    },
    watch: {
      employee_type() {
        this.$nextTick((() => {
          this.update();
        }));
      },
      work_type() {
        this.$nextTick(() => {
          this.update();
        })
      }
    },
    methods: {
      update() {
        this.$nextTick(() => {
          this.$emit('input', {
            search_type: this.$refs.type.value,
            search_value: this.$refs.value.value,
            employee_type: this.employee_type,
            work_type: this.work_type,
          });
        });
      },
      search() {
        this.$emit('search');
      }
    }
  }
</script>
