<template>
  <div>
    <div class="login-info">
      페이호연은 호연회계법인의 회원사를 위한 급여관리시스템 입니다.
    </div>
    <div class="auth-form login-form">
      <login-form />
    </div>
    <div class="login-info-bottom">
      페이호연은 구글 크롬 브라우져에 최적화 되어 있습니다.
    </div>
  </div>
</template>
<script>
  import LoginForm from "@/components/auth/LoginForm";

  export default {
    components: {
      LoginForm
    }
  }
</script>
