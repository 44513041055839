<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user-plus"></i> 입사 처리중
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>사원코드</th>
            <th>이름</th>
            <th style="width: 90px;">입사일</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(employee, index) in employees">
            <tr>
              <td>{{ employee.code }}</td>
              <td>{{ employee.name }}</td>
              <td>{{ employee.in_date }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('employee', {
        employees: 'notCompletedNewEmployees'
      })
    }
  }
</script>
