import {DispatchContext, Job} from "@/core/types";
import {getEmployees, SearchEmployeeData} from "@/core/api/employee";

export default class LoadEmployeesJob implements Job {
  private readonly page: number;

  constructor(page: number) {
    this.page = page;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getEmployees(this.page, {
      search_type: context.store.state.employee.search.search_type,
      search_value: context.store.state.employee.search.search_value,
      employee_type: context.store.state.employee.search.employee_type,
      work_type: context.store.state.employee.search.work_type
    });

    context.store.commit('employee/setEmployees', response.data.data);
  }
}
