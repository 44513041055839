import UsageTermsView from '@/views/terms/Usage.vue';
import PrivacyTermsView from '@/views/terms/Privacy.vue';

export default [
  {
    path: 'usage',
    component: UsageTermsView,
    name: 'terms.usage'
  },
  {
    path: 'privacy',
    component: PrivacyTermsView,
    name: 'terms.privacy'
  }
]
