<template>
  <div class="container">
    <h1 class="page-title">설정</h1>
    <ul class="list-group">
      <li class="list-group-item clearfix">
        푸시알림 설정
        <div class="material-switch pull-right">
          <input id="someSwitchOptionPrimary" name="someSwitchOption001" type="checkbox" v-model="pushEnabled" @click="togglePush"/>
          <label for="someSwitchOptionPrimary" class="label-primary"></label>
        </div>
      </li>
    </ul>
    <button type="button" class="btn btn-primary" @click="back">
      닫기
    </button>
  </div>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        pushEnabled: false
      };
    },
    computed: {
      ...mapState('app', {
        deviceToken: state => state.deviceToken
      })
    },
    methods: {
      back() {
        this.$router.back();
      },
      togglePush() {
      },
      checkPushEnabled() {

      }
    },
    created() {
      if (this.deviceToken !== null) {
        this.checkPushEnabled();
      }
    }
  }
</script>
