<template>
  <div class="container">
    <h1 class="page-title">입사자 추가</h1>
    <create-form />
  </div>
</template>
<script>
  import CreateForm from "@/components/employee/manage/CreateForm.vue";

  export default {
    components: {
      CreateForm
    }
  }
</script>
