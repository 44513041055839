<template>
  <div>
    <div class="form-inline">
      <div class="form-group">
        <label for="dependent_name">성명</label>
        <v-input
          type="text"
          id="dependent_name"
          v-model="name"
        />
      </div>
      &nbsp;
      <div class="form-group">
        <label for="dependent_relationship">관계</label>
        <v-input
          type="text"
          id="dependent_relationship"
          v-model="relationship"
        />
      </div>
      &nbsp;
      <div class="form-group">
        <label style="color: white">&nbsp;_</label><br />
        <button type="button" class="btn btn-default" @click="add">
          추가
        </button>
      </div>
    </div>
    <br />
    <table class="table table-bordered">
      <colgroup>
        <col width="*" />
        <col width="*" />
        <col width="80" />
      </colgroup>
      <thead>
      <tr>
        <th>이름</th>
        <th>관계</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(dependent, index) in value">
        <tr :key="`dependent_${index}`">
          <td>{{ dependent.name }}</td>
          <td>{{ dependent.relationship }}</td>
          <td>
            <button type="button" class="btn btn-default" @click="destroy(index)">
              삭제
            </button>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    props: ['value'],
    data() {
      return {
        name: '',
        relationship: ''
      }
    },
    methods: {
      add() {
        this.$emit('input', [
          ...this.value,
          {
            name: this.name,
            relationship: this.relationship
          }
        ]);

        this.name = '';
        this.relationship = '';
      },
      destroy(index) {
        let value = [
          ...this.value
        ];

        value.splice(index, 1);

        this.$emit('input', value);
      }
    }
  }
</script>
