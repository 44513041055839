import {DispatchContext, Job} from "@/core/types";
import {getEmployeePayslip} from "@/core/api/payslip";

export default class LoadEmployeePropertiesJob implements Job {
  private year: string;
  private month: string;
  private employeeId: any;

  constructor(year: any, month: any, employeeId: any) {
    this.year = year;
    this.month = month;
    this.employeeId = employeeId;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getEmployeePayslip(this.employeeId, this.year, this.month);

    return response.data.data;
  }
}
