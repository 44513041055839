// @ts-ignore
import jscookie from 'js-cookie';
import {DispatchContext, Job} from "@/core/types";

import * as authApi from '@/core/api/auth';
import postMessage from "@/core/post-message";

export default class LoginJob implements Job {
  private readonly userid: string;
  private readonly remember: boolean;
  private readonly password: string;

  constructor(userid: string, password: string, remember: boolean = false) {
    this.remember = remember;
    this.userid = userid;
    this.password = password;
  }

  async run(context: DispatchContext): Promise<any> {
    await context.authManager.login(this.userid, this.password);
    const userResponse = await authApi.user();

    context.store.commit('auth/setUser', userResponse.data.data);

    postMessage(JSON.stringify({
      type: 'login',
      userid: this.userid,
      password: this.password
    }));
  }
}
