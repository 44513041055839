<template>
  <div>
    <div>
      <router-view />
    </div>
  </div>
</template>
<script>
  import TopSubMenu from "@/components/layouts/TopSubMenu.vue";

  export default {
    data() {
      return {
        menus: [
          {
            label: '사원 목록',
            link: 'employee.manage.index'
          },
          {
            label: '입사자 추가',
            link: 'employee.manage.create'
          }
        ]
      };
    },
    components: {
      TopSubMenu
    }
  }
</script>
