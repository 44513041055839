<template>
  <div class="alarm-card">
    <div class="card-heading">
      호연 공지사항
    </div>
    <div class="card-body">
      <table class="announcements">
        <tbody>
        <template v-for="notice in announcements">
          <tr>
            <td class="date">{{ notice.created_at }}</td>
            <td class="text">
              <router-link
                :to="{name: 'announcement.notice.show', params: {id: notice.id}}"
              >
                {{ notice.title }}
              </router-link>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
      <div class="text-right">
        <router-link
          :to="{name: 'announcement.notice.index'}"
          class="btn btn-primary"
        >
          <i class="fal fa-plus"></i> 더보기
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('announcement', {
        announcements: state => state.announcements.slice(0, 10)
      })
    }
  }
</script>
