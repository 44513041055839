<template>
  <div class="app">
    <app-header v-if="user !== null" />
    <div class="content">
      <router-view />
    </div>
    <app-footer v-if="user !== null" />
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
  </div>
</template>
<script>
  import Loading from 'vue-loading-overlay';
  import AppHeader from '@/components/layouts/Header.vue';
  import AppFooter from '@/components/layouts/Footer.vue';
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('auth', {
        user: state => state.user
      }),
      ...mapState('app', {
        deviceToken: state => state.deviceToken
      }),
      ...mapState({
        isLoading: state => state.isLoading
      })
    },
    components: {
      AppHeader,
      AppFooter,
      Loading
    }
  }
</script>
