<template>
  <div>
    <template v-for="(v, index) in value">
      <div class="form-group">
        <label :for="`property_${index}`">{{ v.name }}</label>
        <template v-if="v.id === 1000">
          <input
            type="text"
            class="form-control"
            :id="`property_${index}`"
            :value="selectedDays.length"
          >
        </template>
        <template v-else>
          <input
            type="text"
            class="form-control"
            :id="`property_${index}`"
            :value="v.value"
            @input="changed($event, index)"
          >
        </template>
      </div>
    </template>
    <br />
    <h4>근로일수</h4>
    <table width="100%" class="working-calendar">
      <template v-for="row in 6">
        <tr>
          <template v-for="col in 6">
            <td
              :class="{active: selectedDays.indexOf(`${((row - 1) * 6) + col}`) >= 0}"
              width="16%"
              @click="toggleDay(`${((row - 1) * 6) + col}`)"
              v-if="((row - 1) * 6) + col < 32"
            >
              {{ ((row - 1) * 6) + col }}
            </td>
          </template>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
  export default {
    props: ['value'],
    computed: {
      selectedDays() {
        const v = this.value.filter(v => v.id === 1000);

        if (v.length === 0) return [];
        if (v[0].comment === null) return [];

        return v[0].comment.split(',');
      }
    },
    methods: {
      toggleDay(day) {
        let days = [...this.selectedDays];
        days = days.filter(v => v !== '');

        if (days.indexOf(day) < 0) {
          days.push(day);
        } else {
          days.splice(days.indexOf(day), 1);
        }

        this.$emit('input', [
          ...this.value.map(v => {

            if (v.id === 1000) {
              return {
                ...v,
                value: days.length,
                comment: days.join(',')
              };
            }

            return {
              ...v
            };
          })
        ])
      },
      changed(ev, index) {
        const values = [...this.value];

        values[index] = {
          ...values[index],
          value: ev.target.value
        };

        this.$emit('input', values);
      }
    },
    mounted() {

    }
  }
</script>
