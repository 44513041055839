<template>
  <div class="container">
    <h1 class="page-title">세무지식</h1>
    <knowhow-list />
  </div>
</template>
<script>
  import KnowhowList from "@/components/announcement/knowhow/KnowhowList";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";

  export default {
    components: {
      KnowhowList
    },
    mounted() {
      dispatch(new LoadAnnouncementJob(0, 'knowhow'));
    }
  }
</script>
