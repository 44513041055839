<template>
  <div class="card">
    <div class="card-heading">
      <i class="fa fa-file-invoice"></i> {{ title }}
      <button type="button" class="btn btn-primary" @click="sendEmail">
        전체사원 이메일 전송
      </button>
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>이름</th>
          <th class="hidden-xs">급여타입</th>
          <th class="hidden-xs">급여총계</th>
          <th class="hidden-xs">공제총계</th>
          <th>차인지급액</th>
          <th width="90"></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="employee in employees">
          <tr>
            <td>{{ employee.name }}</td>
            <td class="hidden-xs">{{ employee.pay_type }}</td>
            <td align="right" class="hidden-xs">{{ employee.total_wages.format() }}</td>
            <td align="right" class="hidden-xs">{{ employee.total_excludes.format() }}</td>
            <td align="right">{{ (employee.total_wages - employee. total_excludes).format() }}</td>
            <td>
              <router-link
                :to="{name: 'invoice.detail', params: {id: employee.id}}"
                class="btn btn-primary"
              >
                상세내역
              </router-link>
            </td>
          </tr>
        </template>
        <tr>
          <th>
            총계
          </th>
          <td class="hidden-xs"></td>
          <td align="right" class="hidden-xs">
            {{ totalEmployeeWage.format() }}
          </td>
          <td align="right" class="hidden-xs">
            {{ totalEmployeeExclude.format() }}
          </td>
          <td align="right">
            {{ (totalEmployeeWage - totalEmployeeExclude).format() }}
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['title', 'payslip', 'employees'],
    computed: {
      totalEmployeeWage() {
        let wage = 0;

        for (let i = 0; i < this.employees.length; i ++) {
          wage += this.employees[i].payslip_values.filter(v => v.type === 'wage' && v.base_property_id !== 1000 && v.base_property_id !== 1001).reduce((a, b) => a + b.value, 0);
        }

        return wage;
      },
      totalEmployeeExclude() {
        let exclude = 0;

        for (let i = 0; i < this.employees.length; i ++) {
          exclude += this.employees[i].payslip_values.filter(v => v.type === 'exclude').reduce((a, b) => a + b.value, 0);
        }

        return exclude;
      }
    },
    methods: {
      sendEmail() {
        this.$emit('send-email', this.employees.map(employee => employee.id));
      },
      getTotalWages(values) {
        return values.filter(v => v.type === 'wage').reduce((a, b) => a + b.value, 0);
      },
      getTotalExcludes(values) {
        return values.filter(v => v.type === 'exclude').reduce((a, b) => a + b.value, 0);
      },
      downloadPayslip(payslip) {
        this.$emit('download', payslip);
      }
    }
  }
</script>
